import axios from 'axios';
import { BACKEND_API_URL } from '@/config.js';

export const getItineraries = async (search='') => {
    //console.log('itineraryServices > getItinerary | msg:', msg);

    try {
        let responses = null;

        if (search) {
            responses = await axios.get(BACKEND_API_URL + `/itinerary/search/${search}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer token'
                    },
                    // timeout: 1000
                }
            );
        } else {
            responses = await axios.get(BACKEND_API_URL + '/itinerary/',
                {
                    // no params
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer token'
                    },
                    // timeout: 1000
                }
            );
        }

        //console.log('itineraryServices > get:', responses);

        //console.log('itineraryServices > getItinerary | response.data:', responses.data);
        return responses.data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getItinerary = async (id) => {
    //console.log(`itineraryServices > getItinerary | id:[${id}] `);

    try {
        const url = BACKEND_API_URL + `/itinerary/${id}`;

        const response = await axios.get(url, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer token'
                },
                //timeout: 3000
            }
        );
        //console.log('itineraryServices > getItinerary response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
    }
};
