<template>
  <div class="header-container">
    <div class="header-background">
      <img src="@/assets/popup/popupHero/hero_default.jpeg" alt="Hero Video" class="hero-image">
    </div>
    <div class="header-content">
      <ClosePopupButton />
      <div class="header-title">
        <span>&#8962;&nbsp;&nbsp;Home</span>
      </div>
      <div class="icon-container">
        <HeroButton icon="favourite.png" altText="favourite" link="" />
        <HeroButton icon="share.png" altText="share" link="" />
      </div>
    </div>
  </div>
</template>

<script>
import HeroButton from './HeroButton.vue';
import ClosePopupButton from '@/components/ui/util/ClosePopupButton.vue';

export default {
  name: 'HomeHeader',
  components: {
    HeroButton,
    ClosePopupButton,
  },
}


</script>


<style scoped>


.header-container {
  position: relative;
  width: 100%;
}

.header-background img {
  width: 100%;
  height: 25vh; /* Adjust the height as needed */
  object-fit: cover;
}

.hero-image {
  width: 100%;
  max-width: 100%;
  height: 190px;
  border-radius: 12px 12px 0 0;
  top:0;
  flex-shrink: 0;
  object-fit: cover;
}


.header-content {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.header-title {
  background-color: white;
  border: none;
  padding: 10px 24px 10px 14px;
  margin: 5px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  position: absolute;
  right: 0;
}


</style>