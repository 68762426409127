export const itineraries = [
    {
        "city": "Kyoto",
        "country": "Japan",
        "image_desc": "Kinkaku-ji",
        "image_url": "https://images.pexels.com/photos/21766390/pexels-photo-21766390/free-photo-of-reflection-of-a-buddhist-temple-on-a-lake.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",

        "itinerary_title": "Experience the Timeless Beauty of Kyoto",
        "trip_duration": "5 days 4 nights",
        "price": 1400,
        "group_size": "2 people",
        "route": "Kyoto",
        "description": "Kyoto, the cultural heart of Japan, is renowned for its classical Buddhist temples, stunning gardens, imperial palaces, Shinto shrines, and traditional wooden houses. From the iconic Fushimi Inari Shrine to the serene Arashiyama Bamboo Grove, Kyoto offers a blend of history, spirituality, and natural beauty. This itinerary encapsulates the essence of Kyoto, combining must-see landmarks with unique cultural experiences.",
        "itinerary": [
            {
                "city": "Kyoto",
                "days": "Day 1",
                "description": "Start your journey with a visit to the famous Fushimi Inari Shrine. Wander through the thousands of red torii gates, then explore the historic Gion district, known for its traditional wooden machiya houses and geisha culture.",
                "highlights": [
                    "Fushimi Inari Shrine: Iconic shrine with thousands of torii gates",
                    "Gion District: Traditional area with geisha culture",
                    "Kiyomizu-dera: Historic temple with stunning views"
                ],
                "coordinates": {
                    "lat": 35.0116,
                    "lng": 135.7681
                }
            },
            {
                "city": "Kyoto",
                "days": "Day 2",
                "description": "Discover the serene Arashiyama Bamboo Grove and the nearby Tenryu-ji Temple. Take a scenic boat ride on the Hozu River and visit the Iwatayama Monkey Park for panoramic views of Kyoto.",
                "highlights": [
                    "Arashiyama Bamboo Grove: Tranquil bamboo forest",
                    "Tenryu-ji Temple: Zen temple with beautiful gardens",
                    "Hozu River Boat Ride: Scenic river experience"
                ],
                "coordinates": {
                    "lat": 35.0095,
                    "lng": 135.6673
                }
            },
            {
                "city": "Kyoto",
                "days": "Day 3",
                "description": "Explore the magnificent Kinkaku-ji (Golden Pavilion) and the nearby Ryoan-ji Temple, famous for its rock garden. In the afternoon, visit the Kyoto Imperial Palace and its beautiful surrounding gardens.",
                "highlights": [
                    "Kinkaku-ji: Stunning Golden Pavilion",
                    "Ryoan-ji Temple: Famous rock garden",
                    "Kyoto Imperial Palace: Historic royal residence"
                ],
                "coordinates": {
                    "lat": 35.0394,
                    "lng": 135.7292
                }
            },
            {
                "city": "Kyoto",
                "days": "Day 4",
                "description": "Immerse yourself in Kyoto's rich cultural heritage with a tea ceremony experience and a visit to the traditional Nishiki Market. In the evening, enjoy a traditional kaiseki dinner at a local ryokan.",
                "highlights": [
                    "Tea Ceremony: Traditional Japanese tea experience",
                    "Nishiki Market: Vibrant market with local delicacies",
                    "Kaiseki Dinner: Traditional multi-course Japanese meal"
                ],
                "coordinates": {
                    "lat": 35.0101,
                    "lng": 135.7556
                }
            },
            {
                "city": "Kyoto",
                "days": "Day 5",
                "description": "End your trip with a visit to the Philosopher's Path, a scenic walk along a cherry-tree-lined canal. Explore the nearby Nanzen-ji Temple and enjoy a relaxing boat ride on Lake Biwa.",
                "highlights": [
                    "Philosopher's Path: Scenic walk along a cherry-tree-lined canal",
                    "Nanzen-ji Temple: Historic temple with beautiful gardens",
                    "Lake Biwa Boat Ride: Relaxing experience on Japan's largest lake"
                ],
                "coordinates": {
                    "lat": 35.0283,
                    "lng": 135.7997
                }
            }
        ]
    },
 
    {
        "city": "Osaka",
        "country": "Japan",
        "image_desc": "Osaka Castle",
        "image_url": "https://images.pexels.com/photos/16636148/pexels-photo-16636148/free-photo-of-osaka-castle-main-tower-osaka-japan.jpeg",
        "itinerary_title": "Uncover the Vibrant Energy of Osaka",
        "trip_duration": "4 days 3 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Osaka",
        "description": "Osaka, known as the 'Nation's Kitchen,' is famous for its modern architecture, vibrant nightlife, and delicious street food. This bustling metropolis offers a mix of historical landmarks and contemporary attractions. This itinerary highlights the best of Osaka, blending cultural experiences with culinary delights.",
        "available_months": "March - May, September - November",
        "itinerary": [
            {
                "city": "Osaka",
                "days": "Day 1",
                "description": "Start your Osaka adventure with a visit to the iconic Osaka Castle. Explore its beautiful grounds and learn about its history. In the evening, visit Dotonbori, a lively area known for its neon lights and street food.",
                "highlights": [
                    "Osaka Castle: Historic castle with beautiful gardens",
                    "Dotonbori: Vibrant area with neon lights and street food",
                    "Hozenji Temple: Quaint temple in the heart of Dotonbori"
                ],
                "coordinates": {
                    "lat": 34.6873,
                    "lng": 135.5259
                }
            },
            {
                "city": "Osaka",
                "days": "Day 2",
                "description": "Discover the underwater world at the Osaka Aquarium Kaiyukan. In the afternoon, take a stroll through the Tempozan Harbor Village and enjoy a ride on the Tempozan Ferris Wheel for stunning views of the city.",
                "highlights": [
                    "Osaka Aquarium Kaiyukan: One of the largest aquariums in the world",
                    "Tempozan Harbor Village: Shopping and entertainment complex",
                    "Tempozan Ferris Wheel: Scenic views of Osaka"
                ],
                "coordinates": {
                    "lat": 34.6545,
                    "lng": 135.4283
                }
            },
            {
                "city": "Osaka",
                "days": "Day 3",
                "description": "Visit the bustling district of Namba. Explore the Shinsaibashi Shopping Arcade and enjoy the culinary delights at Kuromon Ichiba Market, known for its fresh seafood and street food.",
                "highlights": [
                    "Namba: Lively district with shopping and entertainment",
                    "Shinsaibashi Shopping Arcade: Popular shopping street",
                    "Kuromon Ichiba Market: Famous market with fresh seafood"
                ],
                "coordinates": {
                    "lat": 34.6643,
                    "lng": 135.5019
                }
            },
            {
                "city": "Osaka",
                "days": "Day 4",
                "description": "Experience the cultural side of Osaka with a visit to the Sumiyoshi Taisha, one of Japan's oldest Shinto shrines. In the afternoon, explore the modern architecture of the Umeda Sky Building and its Floating Garden Observatory.",
                "highlights": [
                    "Sumiyoshi Taisha: Historic Shinto shrine",
                    "Umeda Sky Building: Modern architectural marvel",
                    "Floating Garden Observatory: Panoramic views of Osaka"
                ],
                "coordinates": {
                    "lat": 34.6124,
                    "lng": 135.4939
                }
            }
        ]
    },
    
    {
        "city": "Hiroshima",
        "country": "Japan",
        "image_desc": "Peace Memorial Park",
        "image_url": "https://images.pexels.com/photos/2516579/pexels-photo-2516579.jpeg",
        "itinerary_title": "Explore the Resilience and Beauty of Hiroshima",
        "trip_duration": "3 days 2 nights",
        "price": 900,
        "group_size": "2 people",
        "route": "Hiroshima",
        "description": "Hiroshima is a city that has risen from its tragic past to become a vibrant and thriving metropolis. Known for its poignant history, beautiful landscapes, and warm hospitality, Hiroshima offers a blend of reflective and rejuvenating experiences. This itinerary highlights the key attractions and cultural experiences in Hiroshima.",
        "available_months": "March - May, October - November",
        "itinerary": [
            {
                "city": "Hiroshima",
                "days": "Day 1",
                "description": "Begin your journey with a visit to the Hiroshima Peace Memorial Park and Museum. Reflect on the city's history and resilience. In the afternoon, explore the Atomic Bomb Dome and take a relaxing walk along the Motoyasu River.",
                "highlights": [
                    "Hiroshima Peace Memorial Park: Memorial to the victims of the atomic bomb",
                    "Peace Memorial Museum: Exhibits on the atomic bombing and its aftermath",
                    "Atomic Bomb Dome: UNESCO World Heritage Site"
                ],
                "coordinates": {
                    "lat": 34.3955,
                    "lng": 132.4536
                }
            },
            {
                "city": "Hiroshima",
                "days": "Day 2",
                "description": "Take a day trip to Miyajima Island, famous for its floating torii gate and Itsukushima Shrine. Enjoy the scenic beauty and cultural heritage of the island. In the evening, return to Hiroshima and explore the bustling Hondori shopping street.",
                "highlights": [
                    "Miyajima Island: Scenic island with cultural heritage",
                    "Itsukushima Shrine: Famous shrine with floating torii gate",
                    "Hondori Shopping Street: Vibrant shopping area"
                ],
                "coordinates": {
                    "lat": 34.2963,
                    "lng": 132.3198
                }
            },
            {
                "city": "Hiroshima",
                "days": "Day 3",
                "description": "Visit the beautiful Shukkeien Garden and Hiroshima Castle. Learn about the castle's history and enjoy the serene garden landscapes. Conclude your trip with a visit to the Hiroshima Museum of Art.",
                "highlights": [
                    "Shukkeien Garden: Historic Japanese garden",
                    "Hiroshima Castle: Reconstructed castle with historical exhibits",
                    "Hiroshima Museum of Art: Collection of modern and traditional art"
                ],
                "coordinates": {
                    "lat": 34.4024,
                    "lng": 132.4608
                }
            }
        ]
    },
    
    {
        "city": "Sapporo",
        "country": "Japan",
        "image_desc": "Odori Park",
        "image_url": "https://images.pexels.com/photos/371634/pexels-photo-371634.jpeg",
        "itinerary_title": "Discover the Charm of Sapporo",
        "trip_duration": "4 days 3 nights",
        "price": 1000,
        "group_size": "2 people",
        "route": "Sapporo",
        "description": "Sapporo, the largest city on the northern island of Hokkaido, is known for its beautiful parks, vibrant festivals, and delicious cuisine. This itinerary offers a mix of cultural experiences, outdoor activities, and culinary delights, capturing the essence of Sapporo.",
        "available_months": "June - September, December - February",
        "itinerary": [
            {
                "city": "Sapporo",
                "days": "Day 1",
                "description": "Start your Sapporo adventure with a visit to Odori Park, the heart of the city. Explore the iconic Sapporo TV Tower and enjoy panoramic views of the city. In the afternoon, visit the historic Clock Tower and the Sapporo Beer Museum.",
                "highlights": [
                    "Odori Park: Central park with seasonal events",
                    "Sapporo TV Tower: Landmark with observation deck",
                    "Clock Tower: Historic building and symbol of Sapporo",
                    "Sapporo Beer Museum: Learn about the history of beer in Sapporo"
                ],
                "coordinates": {
                    "lat": 43.0618,
                    "lng": 141.3562
                }
            },
            {
                "city": "Sapporo",
                "days": "Day 2",
                "description": "Discover the beauty of Moerenuma Park, designed by renowned sculptor Isamu Noguchi. In the afternoon, visit the Hokkaido University campus and its beautiful ginkgo tree-lined avenues. In the evening, explore the Susukino district, known for its nightlife and dining options.",
                "highlights": [
                    "Moerenuma Park: Art and nature park designed by Isamu Noguchi",
                    "Hokkaido University: Scenic campus with historic buildings",
                    "Susukino: Vibrant nightlife district"
                ],
                "coordinates": {
                    "lat": 43.1142,
                    "lng": 141.4090
                }
            },
            {
                "city": "Sapporo",
                "days": "Day 3",
                "description": "Take a day trip to the nearby Otaru, a charming port city known for its well-preserved canal area and glassworks. Visit the Otaru Canal, explore the Otaru Music Box Museum, and enjoy fresh seafood at the local markets.",
                "highlights": [
                    "Otaru Canal: Picturesque canal with historic buildings",
                    "Otaru Music Box Museum: Unique music box exhibits",
                    "Otaru Sakaimachi Street: Shopping street with local crafts and foods"
                ],
                "coordinates": {
                    "lat": 43.1979,
                    "lng": 140.9947
                }
            },
            {
                "city": "Sapporo",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the historic Hokkaido Shrine, located in Maruyama Park. Explore the park and enjoy the natural beauty. In the afternoon, visit the Sapporo Art Park and its outdoor sculpture garden.",
                "highlights": [
                    "Hokkaido Shrine: Historic shrine in a serene setting",
                    "Maruyama Park: Beautiful park with walking trails",
                    "Sapporo Art Park: Art museum with outdoor sculptures"
                ],
                "coordinates": {
                    "lat": 43.0547,
                    "lng": 141.2982
                }
            }
        ]
    },
    
    {
        "city": "Seoul",
        "country": "South Korea",
        "image_desc": "Gyeongbokgung Palace",
        "image_url": "https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg",
        "itinerary_title": "Experience the Vibrant Culture of Seoul",
        "trip_duration": "5 days 4 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Seoul",
        "description": "Seoul, the capital city of South Korea, is a bustling metropolis where modern skyscrapers meet ancient temples. Known for its rich history, vibrant culture, and delicious cuisine, Seoul offers a diverse range of experiences. This itinerary highlights the best of Seoul, from historical landmarks to contemporary attractions.",
        "available_months": "March - May, September - November",
        "itinerary": [
            {
                "city": "Seoul",
                "days": "Day 1",
                "description": "Start your Seoul adventure with a visit to Gyeongbokgung Palace, the largest of the Five Grand Palaces. Explore the National Folk Museum and watch the changing of the guard ceremony. In the afternoon, visit Bukchon Hanok Village to experience traditional Korean houses.",
                "highlights": [
                    "Gyeongbokgung Palace: Historic palace with beautiful architecture",
                    "National Folk Museum: Museum showcasing Korean culture and history",
                    "Bukchon Hanok Village: Traditional Korean village with hanok houses"
                ],
                "coordinates": {
                    "lat": 37.5796,
                    "lng": 126.9770
                }
            },
            {
                "city": "Seoul",
                "days": "Day 2",
                "description": "Visit the N Seoul Tower on Namsan Mountain for panoramic views of the city. In the afternoon, explore Myeongdong, a bustling shopping district known for its street food and cosmetics shops. In the evening, enjoy a traditional Korean BBQ dinner.",
                "highlights": [
                    "N Seoul Tower: Iconic tower with observation decks",
                    "Myeongdong: Popular shopping district with street food",
                    "Korean BBQ: Traditional Korean dining experience"
                ],
                "coordinates": {
                    "lat": 37.5512,
                    "lng": 126.9882
                }
            },
            {
                "city": "Seoul",
                "days": "Day 3",
                "description": "Discover the historic district of Insadong, known for its traditional tea houses, antique shops, and art galleries. Visit Jogyesa Temple, the center of Zen Buddhism in Korea. In the evening, explore the Dongdaemun Design Plaza, a major urban development landmark.",
                "highlights": [
                    "Insadong: Cultural district with traditional tea houses and shops",
                    "Jogyesa Temple: Central temple of Zen Buddhism in Korea",
                    "Dongdaemun Design Plaza: Modern architectural marvel"
                ],
                "coordinates": {
                    "lat": 37.5703,
                    "lng": 126.9820
                }
            },
            {
                "city": "Seoul",
                "days": "Day 4",
                "description": "Take a trip to the Demilitarized Zone (DMZ) to learn about the history and current state of the Korean Peninsula. Visit key sites such as the Third Tunnel and Dora Observatory. In the evening, return to Seoul and explore the vibrant Hongdae district, known for its nightlife and youthful atmosphere.",
                "highlights": [
                    "DMZ: Demilitarized Zone between North and South Korea",
                    "Third Tunnel: Tunnel dug by North Korea for a potential invasion",
                    "Dora Observatory: Viewpoint overlooking North Korea",
                    "Hongdae: Lively district known for nightlife and entertainment"
                ],
                "coordinates": {
                    "lat": 37.5665,
                    "lng": 126.9780
                }
            },
            {
                "city": "Seoul",
                "days": "Day 5",
                "description": "End your trip with a relaxing visit to the Cheonggyecheon Stream, an urban renewal project with walking paths and fountains. Visit the nearby Gwangjang Market for a taste of traditional Korean street food. In the afternoon, explore the trendy Garosugil area in Gangnam, known for its stylish boutiques and cafes.",
                "highlights": [
                    "Cheonggyecheon Stream: Restored stream with walking paths",
                    "Gwangjang Market: Traditional market with street food",
                    "Garosugil: Trendy area with boutiques and cafes"
                ],
                "coordinates": {
                    "lat": 37.5702,
                    "lng": 126.9919
                }
            }
        ]
    },
    
    {
        "city": "Busan",
        "country": "South Korea",
        "image_desc": "Haeundae Beach",
        "image_url": "https://images.pexels.com/photos/1200248/pexels-photo-1200248.jpeg",
        "itinerary_title": "Experience the Coastal Beauty of Busan",
        "trip_duration": "4 days 3 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Busan",
        "description": "Busan, South Korea's second-largest city, is renowned for its stunning beaches, scenic mountains, and vibrant culture. This coastal city offers a unique blend of natural beauty and urban excitement. This itinerary captures the essence of Busan, from its historic temples to its bustling markets.",
        "available_months": "May - October",
        "itinerary": [
            {
                "city": "Busan",
                "days": "Day 1",
                "description": "Start your Busan adventure with a visit to Haeundae Beach, one of Korea's most famous beaches. Enjoy the sun and surf, then explore the nearby Dongbaekseom Island with its scenic walking trails and Nurimaru APEC House.",
                "highlights": [
                    "Haeundae Beach: Popular beach with soft sand and clear water",
                    "Dongbaekseom Island: Scenic island with walking trails",
                    "Nurimaru APEC House: Modern conference hall with beautiful views"
                ],
                "coordinates": {
                    "lat": 35.1587,
                    "lng": 129.1603
                }
            },
            {
                "city": "Busan",
                "days": "Day 2",
                "description": "Visit the historic Beomeosa Temple, located on the slopes of Geumjeongsan Mountain. In the afternoon, explore the colorful Gamcheon Culture Village, known for its art murals and vibrant houses. End the day with a visit to Jagalchi Fish Market, the largest seafood market in Korea.",
                "highlights": [
                    "Beomeosa Temple: Historic Buddhist temple on Geumjeongsan Mountain",
                    "Gamcheon Culture Village: Artistic village with colorful houses",
                    "Jagalchi Fish Market: Largest seafood market in Korea"
                ],
                "coordinates": {
                    "lat": 35.2365,
                    "lng": 129.0847
                }
            },
            {
                "city": "Busan",
                "days": "Day 3",
                "description": "Discover the beauty of Taejongdae, a natural park with stunning cliffs and ocean views. Take a ride on the Danubi Train for a scenic tour. In the afternoon, visit Gwangalli Beach and enjoy the view of the illuminated Gwangan Bridge in the evening.",
                "highlights": [
                    "Taejongdae: Natural park with cliffs and ocean views",
                    "Danubi Train: Scenic train ride through Taejongdae",
                    "Gwangalli Beach: Beach with views of Gwangan Bridge"
                ],
                "coordinates": {
                    "lat": 35.0514,
                    "lng": 129.0848
                }
            },
            {
                "city": "Busan",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Busan Tower in Yongdusan Park for panoramic views of the city. Explore the nearby BIFF Square, a bustling area named after the Busan International Film Festival, and shop for souvenirs at Gukje Market.",
                "highlights": [
                    "Busan Tower: Observation tower with city views",
                    "Yongdusan Park: Park with walking paths and Busan Tower",
                    "BIFF Square: Lively area named after the Busan International Film Festival",
                    "Gukje Market: Traditional market with a variety of goods"
                ],
                "coordinates": {
                    "lat": 35.1009,
                    "lng": 129.0323
                }
            }
        ]
    },
    
    {
        "city": "Incheon",
        "country": "South Korea",
        "image_desc": "Incheon Chinatown",
        "image_url": "https://images.pexels.com/photos/208732/pexels-photo-208732.jpeg",
        "itinerary_title": "Discover the Gateway of Korea: Incheon",
        "trip_duration": "3 days 2 nights",
        "price": 900,
        "group_size": "2 people",
        "route": "Incheon",
        "description": "Incheon, known as the gateway to South Korea, offers a blend of modern attractions and historical sites. This vibrant city is home to bustling markets, scenic waterfronts, and significant cultural landmarks. This itinerary captures the highlights of Incheon, providing a mix of exploration and relaxation.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Incheon",
                "days": "Day 1",
                "description": "Begin your Incheon adventure with a visit to the lively Chinatown. Explore the vibrant streets and enjoy authentic Chinese cuisine. In the afternoon, head to the Incheon Open Port Area to learn about the city's history and visit the historic Jemulpo Club.",
                "highlights": [
                    "Incheon Chinatown: Colorful district with Chinese shops and restaurants",
                    "Incheon Open Port Area: Historic area with museums and old buildings",
                    "Jemulpo Club: Historic social club from the open port era"
                ],
                "coordinates": {
                    "lat": 37.4753,
                    "lng": 126.6163
                }
            },
            {
                "city": "Incheon",
                "days": "Day 2",
                "description": "Spend the day at Songdo Central Park, a modern urban park with scenic waterways and green spaces. Rent a bicycle or take a boat ride for a leisurely experience. In the evening, visit the NC Cube Canal Walk, a unique shopping complex with picturesque canals and boutiques.",
                "highlights": [
                    "Songdo Central Park: Modern park with waterways and green spaces",
                    "Bicycle and Boat Rentals: Leisure activities in the park",
                    "NC Cube Canal Walk: Shopping complex with canals and boutiques"
                ],
                "coordinates": {
                    "lat": 37.3925,
                    "lng": 126.6372
                }
            },
            {
                "city": "Incheon",
                "days": "Day 3",
                "description": "Conclude your trip with a visit to Wolmido Island, known for its amusement park and beautiful seaside views. Enjoy the rides at Wolmi Theme Park and take a relaxing walk along the Wolmi Culture Street. Finish the day with fresh seafood at one of the island's many restaurants.",
                "highlights": [
                    "Wolmido Island: Seaside island with amusement park and cultural street",
                    "Wolmi Theme Park: Amusement park with various rides",
                    "Wolmi Culture Street: Scenic street with shops and restaurants"
                ],
                "coordinates": {
                    "lat": 37.4755,
                    "lng": 126.5986
                }
            }
        ]
    },
    
    {
        "city": "Jeju",
        "country": "South Korea",
        "image_desc": "Seongsan Ilchulbong",
        "image_url": "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg",
        "itinerary_title": "Explore the Natural Wonders of Jeju Island",
        "trip_duration": "5 days 4 nights",
        "price": 1300,
        "group_size": "2 people",
        "route": "Jeju",
        "description": "Jeju Island, known as the 'Island of the Gods,' is a popular destination in South Korea, famed for its stunning natural landscapes, unique cultural experiences, and beautiful beaches. This itinerary offers a comprehensive exploration of Jeju's diverse attractions, from volcanic craters to scenic waterfalls.",
        "available_months": "March - June, September - November",
        "itinerary": [
            {
                "city": "Jeju",
                "days": "Day 1",
                "description": "Start your Jeju adventure with a visit to Hallasan National Park. Hike up to the summit of Hallasan Mountain, the highest peak in South Korea, and enjoy the breathtaking views. In the afternoon, relax at Jeju Loveland, an outdoor sculpture park.",
                "highlights": [
                    "Hallasan National Park: Hiking trails and scenic views",
                    "Hallasan Mountain: Highest peak in South Korea",
                    "Jeju Loveland: Unique outdoor sculpture park"
                ],
                "coordinates": {
                    "lat": 33.3617,
                    "lng": 126.5292
                }
            },
            {
                "city": "Jeju",
                "days": "Day 2",
                "description": "Visit the iconic Seongsan Ilchulbong (Sunrise Peak), a UNESCO World Heritage site formed by a volcanic eruption. Enjoy the sunrise view and explore the surrounding area. In the afternoon, visit the Manjanggul Cave, one of the longest lava tubes in the world.",
                "highlights": [
                    "Seongsan Ilchulbong: Volcanic crater with stunning sunrise views",
                    "Manjanggul Cave: Extensive lava tube with impressive formations"
                ],
                "coordinates": {
                    "lat": 33.4583,
                    "lng": 126.9411
                }
            },
            {
                "city": "Jeju",
                "days": "Day 3",
                "description": "Explore Jeju's beautiful waterfalls, starting with Cheonjiyeon Waterfall. In the afternoon, visit Jeongbang Waterfall, the only waterfall in Asia that falls directly into the ocean. End the day with a visit to the Oedolgae Rock, a scenic coastal rock formation.",
                "highlights": [
                    "Cheonjiyeon Waterfall: Picturesque waterfall in a lush setting",
                    "Jeongbang Waterfall: Unique waterfall falling into the ocean",
                    "Oedolgae Rock: Scenic coastal rock formation"
                ],
                "coordinates": {
                    "lat": 33.2472,
                    "lng": 126.5615
                }
            },
            {
                "city": "Jeju",
                "days": "Day 4",
                "description": "Visit the Jeju Folk Village Museum to learn about the island's cultural heritage. In the afternoon, explore the scenic coastal road to Seopjikoji, a popular filming location for Korean dramas. Enjoy the panoramic ocean views and visit the lighthouse.",
                "highlights": [
                    "Jeju Folk Village Museum: Cultural heritage site with traditional houses",
                    "Seopjikoji: Scenic coastal area with lighthouse and ocean views"
                ],
                "coordinates": {
                    "lat": 33.3231,
                    "lng": 126.8411
                }
            },
            {
                "city": "Jeju",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Teddy Bear Museum, a fun and quirky attraction featuring teddy bears from around the world. In the afternoon, relax at Hyeopjae Beach, known for its white sand and clear blue waters. Enjoy fresh seafood at a nearby restaurant before heading home.",
                "highlights": [
                    "Teddy Bear Museum: Unique museum with teddy bear exhibits",
                    "Hyeopjae Beach: Beautiful beach with white sand and clear waters",
                    "Fresh Seafood: Local delicacies at nearby restaurants"
                ],
                "coordinates": {
                    "lat": 33.3946,
                    "lng": 126.2396
                }
            }
        ]
    },
    
    {
        "city": "Nagoya",
        "country": "Japan",
        "image_desc": "Nagoya Castle",
        "image_url": "https://images.pexels.com/photos/213200/pexels-photo-213200.jpeg",
        "itinerary_title": "Discover the Blend of Tradition and Innovation in Nagoya",
        "trip_duration": "4 days 3 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Nagoya",
        "description": "Nagoya, a major city in Japan's Aichi Prefecture, is known for its blend of historic sites, modern industries, and rich cultural heritage. This itinerary captures the essence of Nagoya, offering a mix of traditional experiences and contemporary attractions.",
        "available_months": "March - June, September - November",
        "itinerary": [
            {
                "city": "Nagoya",
                "days": "Day 1",
                "description": "Start your Nagoya adventure with a visit to Nagoya Castle, a historic landmark with beautiful gardens and impressive architecture. In the afternoon, explore the Osu Shopping District, known for its mix of traditional shops and modern boutiques.",
                "highlights": [
                    "Nagoya Castle: Historic castle with beautiful gardens",
                    "Osu Shopping District: Vibrant shopping area with diverse stores",
                    "Osu Kannon Temple: Historic Buddhist temple in the shopping district"
                ],
                "coordinates": {
                    "lat": 35.1852,
                    "lng": 136.8992
                }
            },
            {
                "city": "Nagoya",
                "days": "Day 2",
                "description": "Visit the Toyota Commemorative Museum of Industry and Technology to learn about the city's automotive heritage. In the afternoon, explore the Atsuta Shrine, one of Japan's most important Shinto shrines, and enjoy the peaceful surroundings.",
                "highlights": [
                    "Toyota Commemorative Museum: Insights into the automotive industry",
                    "Atsuta Shrine: Historic Shinto shrine with serene atmosphere",
                    "Shirotori Garden: Traditional Japanese garden near Atsuta Shrine"
                ],
                "coordinates": {
                    "lat": 35.1417,
                    "lng": 136.9001
                }
            },
            {
                "city": "Nagoya",
                "days": "Day 3",
                "description": "Discover the cultural treasures of the Tokugawa Art Museum, which houses samurai artifacts and traditional Japanese art. In the afternoon, visit the Nagoya City Science Museum, featuring a large planetarium and interactive exhibits.",
                "highlights": [
                    "Tokugawa Art Museum: Museum with samurai artifacts and traditional art",
                    "Tokugawaen Garden: Beautiful Japanese garden near the museum",
                    "Nagoya City Science Museum: Interactive exhibits and large planetarium"
                ],
                "coordinates": {
                    "lat": 35.1718,
                    "lng": 136.9114
                }
            },
            {
                "city": "Nagoya",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the SCMAGLEV and Railway Park, showcasing Japan's railway history and the future of train technology. In the afternoon, relax at the Higashiyama Zoo and Botanical Gardens, which offer a variety of flora and fauna to explore.",
                "highlights": [
                    "SCMAGLEV and Railway Park: Museum dedicated to Japan's railway history",
                    "Higashiyama Zoo and Botanical Gardens: Zoo and gardens with diverse exhibits",
                    "Sky Tower: Observation deck with panoramic views of Nagoya"
                ],
                "coordinates": {
                    "lat": 35.1532,
                    "lng": 137.0085
                }
            }
        ]
    },
    
    {
        "city": "Fukuoka",
        "country": "Japan",
        "image_desc": "Tochoji Temple",
        "image_url": "https://images.pexels.com/photos/19385241/pexels-photo-19385241/free-photo-of-tochoji-temple-in-fukuoka.jpeg",
        "itinerary_title": "Experience the Dynamic City of Fukuoka",
        "trip_duration": "4 days 3 nights",
        "price": 1050,
        "group_size": "2 people",
        "route": "Fukuoka",
        "description": "Fukuoka, located on Japan's Kyushu Island, is known for its rich history, vibrant nightlife, and delectable cuisine. This itinerary provides a comprehensive exploration of Fukuoka, blending cultural experiences with modern attractions.",
        "available_months": "April - June, September - November",
        "itinerary": [
            {
                "city": "Fukuoka",
                "days": "Day 1",
                "description": "Start your journey with a visit to Ohori Park, a beautiful park with a large pond and walking trails. Explore the nearby Fukuoka Castle ruins and enjoy the views from the observation deck. In the evening, visit the bustling Nakasu district, known for its nightlife and yatai food stalls.",
                "highlights": [
                    "Ohori Park: Scenic park with a large pond",
                    "Fukuoka Castle: Historic castle ruins with observation deck",
                    "Nakasu: Lively district with nightlife and yatai food stalls"
                ],
                "coordinates": {
                    "lat": 33.5897,
                    "lng": 130.3784
                }
            },
            {
                "city": "Fukuoka",
                "days": "Day 2",
                "description": "Discover the cultural heritage of Fukuoka with a visit to the Kushida Shrine, one of the city's oldest Shinto shrines. In the afternoon, explore the Hakata Machiya Folk Museum to learn about traditional life in Fukuoka. End the day with shopping at the Canal City Hakata, a large shopping and entertainment complex.",
                "highlights": [
                    "Kushida Shrine: Historic Shinto shrine",
                    "Hakata Machiya Folk Museum: Museum showcasing traditional life",
                    "Canal City Hakata: Shopping and entertainment complex"
                ],
                "coordinates": {
                    "lat": 33.5951,
                    "lng": 130.4108
                }
            },
            {
                "city": "Fukuoka",
                "days": "Day 3",
                "description": "Take a day trip to the nearby Dazaifu, a town known for its historic Tenmangu Shrine dedicated to the deity of learning. Visit the Kyushu National Museum to explore its extensive collection of artifacts. In the evening, return to Fukuoka and enjoy a relaxing stroll along the Momochi Seaside Park.",
                "highlights": [
                    "Dazaifu Tenmangu Shrine: Historic shrine dedicated to the deity of learning",
                    "Kyushu National Museum: Museum with extensive collection of artifacts",
                    "Momochi Seaside Park: Coastal park with beautiful views"
                ],
                "coordinates": {
                    "lat": 33.5256,
                    "lng": 130.5382
                }
            },
            {
                "city": "Fukuoka",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Fukuoka Tower, Japan's tallest seaside tower offering panoramic views of the city. In the afternoon, explore the Fukuoka City Museum to learn about the city's history. End your trip with a relaxing visit to the Maizuru Park, especially beautiful during cherry blossom season.",
                "highlights": [
                    "Fukuoka Tower: Tallest seaside tower with panoramic views",
                    "Fukuoka City Museum: Museum detailing the city's history",
                    "Maizuru Park: Beautiful park, especially during cherry blossom season"
                ],
                "coordinates": {
                    "lat": 33.5936,
                    "lng": 130.3515
                }
            }
        ]
    },

    {
        "city": "New York City",
        "country": "USA",
        "image_desc": "Statue of Liberty",
        "image_url": "https://images.pexels.com/photos/466685/pexels-photo-466685.jpeg",
        "itinerary_title": "Discover the Energy of New York City",
        "trip_duration": "5 days 4 nights",
        "price": 1500,
        "group_size": "2 people",
        "route": "New York City",
        "description": "New York City, known as 'The City That Never Sleeps,' is famous for its iconic landmarks, diverse culture, and vibrant neighborhoods. This itinerary offers a mix of must-see attractions and unique experiences that capture the essence of NYC.",
        "available_months": "April - June, September - November",
        "itinerary": [
            {
                "city": "New York City",
                "days": "Day 1",
                "description": "Begin your New York City adventure with a visit to Times Square, the bustling heart of the city. Explore the nearby Broadway theaters and catch a world-class show in the evening. Walk through Central Park to relax and enjoy the city's green oasis.",
                "highlights": [
                    "Times Square: Iconic area known for its bright lights and entertainment",
                    "Broadway: Famous theater district with world-class shows",
                    "Central Park: Large urban park with various attractions"
                ],
                "coordinates": {
                    "lat": 40.7580,
                    "lng": -73.9855
                }
            },
            {
                "city": "New York City",
                "days": "Day 2",
                "description": "Visit the Statue of Liberty and Ellis Island to learn about the history of immigration in the United States. In the afternoon, explore the financial district and see the iconic Wall Street and the 9/11 Memorial & Museum.",
                "highlights": [
                    "Statue of Liberty: Symbol of freedom and democracy",
                    "Ellis Island: Historic immigration center",
                    "Wall Street: Financial district with iconic landmarks",
                    "9/11 Memorial & Museum: Tribute to the victims of September 11"
                ],
                "coordinates": {
                    "lat": 40.6892,
                    "lng": -74.0445
                }
            },
            {
                "city": "New York City",
                "days": "Day 3",
                "description": "Explore the artistic side of NYC with a visit to the Metropolitan Museum of Art. In the afternoon, walk along the High Line, an elevated park built on a historic freight rail line. Finish the day with dinner and shopping in the trendy Meatpacking District.",
                "highlights": [
                    "Metropolitan Museum of Art: One of the world's largest and finest art museums",
                    "High Line: Elevated park with beautiful views of the city",
                    "Meatpacking District: Trendy area with dining and shopping"
                ],
                "coordinates": {
                    "lat": 40.7794,
                    "lng": -73.9632
                }
            },
            {
                "city": "New York City",
                "days": "Day 4",
                "description": "Visit the American Museum of Natural History to explore its extensive exhibits. In the afternoon, take a walk across the Brooklyn Bridge and enjoy the views of the Manhattan skyline. Spend the evening in Brooklyn, exploring the DUMBO area and its waterfront parks.",
                "highlights": [
                    "American Museum of Natural History: Extensive exhibits on natural history",
                    "Brooklyn Bridge: Iconic bridge with stunning views",
                    "DUMBO: Trendy Brooklyn neighborhood with parks and waterfront views"
                ],
                "coordinates": {
                    "lat": 40.7813,
                    "lng": -73.9735
                }
            },
            {
                "city": "New York City",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Empire State Building for panoramic views of the city. In the afternoon, explore the Museum of Modern Art (MoMA) and enjoy its impressive collection of contemporary art. Finish your trip with a stroll through Fifth Avenue, known for its high-end shopping and iconic landmarks.",
                "highlights": [
                    "Empire State Building: Iconic skyscraper with observation decks",
                    "Museum of Modern Art (MoMA): Renowned museum with contemporary art",
                    "Fifth Avenue: Famous shopping street with luxury stores and landmarks"
                ],
                "coordinates": {
                    "lat": 40.7488,
                    "lng": -73.9857
                }
            }
        ]
    },
    
    {
        "city": "Rome",
        "country": "Italy",
        "image_desc": "Colosseum",
        "image_url": "https://images.pexels.com/photos/356854/pexels-photo-356854.jpeg",
        "itinerary_title": "Experience the Eternal City: Rome",
        "trip_duration": "5 days 4 nights",
        "price": 1400,
        "group_size": "2 people",
        "route": "Rome",
        "description": "Rome, the capital city of Italy, is a city steeped in history and culture. Known for its ancient ruins, stunning architecture, and delicious cuisine, Rome offers an unforgettable experience. This itinerary covers the must-see attractions and hidden gems of Rome.",
        "available_months": "March - June, September - November",
        "itinerary": [
            {
                "city": "Rome",
                "days": "Day 1",
                "description": "Begin your Roman adventure with a visit to the Colosseum, the largest amphitheater ever built. Explore the Roman Forum and Palatine Hill to get a glimpse into ancient Roman life. In the evening, enjoy dinner in the charming Trastevere neighborhood.",
                "highlights": [
                    "Colosseum: Iconic ancient amphitheater",
                    "Roman Forum: Ruins of ancient Rome's center",
                    "Palatine Hill: Historic site with stunning views",
                    "Trastevere: Charming neighborhood with restaurants and nightlife"
                ],
                "coordinates": {
                    "lat": 41.8902,
                    "lng": 12.4922
                }
            },
            {
                "city": "Rome",
                "days": "Day 2",
                "description": "Visit the Vatican City, starting with St. Peter's Basilica and the Vatican Museums. Marvel at the Sistine Chapel and its famous ceiling painted by Michelangelo. In the afternoon, explore Castel Sant'Angelo and enjoy the views of the Tiber River.",
                "highlights": [
                    "St. Peter's Basilica: Iconic church with stunning architecture",
                    "Vatican Museums: Extensive art collections and the Sistine Chapel",
                    "Castel Sant'Angelo: Historic fortress with panoramic views"
                ],
                "coordinates": {
                    "lat": 41.9029,
                    "lng": 12.4534
                }
            },
            {
                "city": "Rome",
                "days": "Day 3",
                "description": "Discover the beauty of the Trevi Fountain and toss a coin to ensure your return to Rome. Visit the nearby Pantheon, one of the best-preserved ancient Roman buildings. Spend the afternoon at Piazza Navona, enjoying its fountains and lively atmosphere.",
                "highlights": [
                    "Trevi Fountain: Famous Baroque fountain",
                    "Pantheon: Ancient Roman temple turned church",
                    "Piazza Navona: Lively square with fountains and cafes"
                ],
                "coordinates": {
                    "lat": 41.9009,
                    "lng": 12.4833
                }
            },
            {
                "city": "Rome",
                "days": "Day 4",
                "description": "Explore the Borghese Gallery and its impressive art collection, set in the beautiful Villa Borghese gardens. In the afternoon, visit the Spanish Steps and enjoy the view from the top. Spend the evening shopping and dining along Via del Corso.",
                "highlights": [
                    "Borghese Gallery: Art museum with extensive collections",
                    "Villa Borghese: Beautiful gardens for a leisurely walk",
                    "Spanish Steps: Iconic staircase with stunning views",
                    "Via del Corso: Popular shopping street"
                ],
                "coordinates": {
                    "lat": 41.9125,
                    "lng": 12.4861
                }
            },
            {
                "city": "Rome",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the ancient Appian Way and the Catacombs of San Callisto. In the afternoon, explore the vibrant Campo de' Fiori market. Finish your trip with a relaxing evening in the Piazza del Popolo, enjoying the local ambiance.",
                "highlights": [
                    "Appian Way: Historic ancient road",
                    "Catacombs of San Callisto: Ancient underground burial sites",
                    "Campo de' Fiori: Lively market square",
                    "Piazza del Popolo: Beautiful square with historic monuments"
                ],
                "coordinates": {
                    "lat": 41.8955,
                    "lng": 12.4823
                }
            }
        ]
    },
    
    {
        "city": "Barcelona",
        "country": "Spain",
        "image_desc": "Sagrada Familia",
        "image_url": "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg",
        "itinerary_title": "Explore the Vibrant Culture of Barcelona",
        "trip_duration": "4 days 3 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Barcelona",
        "description": "Barcelona, the capital of Catalonia, is known for its unique blend of historic charm and modernist architecture. With its stunning beaches, world-class cuisine, and vibrant culture, Barcelona offers an unforgettable travel experience. This itinerary captures the essence of Barcelona, from Gaudí's masterpieces to the bustling markets.",
        "available_months": "April - June, September - November",
        "itinerary": [
            {
                "city": "Barcelona",
                "days": "Day 1",
                "description": "Begin your Barcelona adventure with a visit to La Sagrada Família, Antoni Gaudí's unfinished masterpiece. Explore the stunning architecture and intricate details of this iconic basilica. In the afternoon, stroll down the famous pedestrian street La Rambla, and visit the bustling La Boqueria Market for a taste of local cuisine.",
                "highlights": [
                    "La Sagrada Família: Gaudí's iconic basilica",
                    "La Rambla: Famous pedestrian street with shops and cafes",
                    "La Boqueria Market: Bustling market with fresh local produce"
                ],
                "coordinates": {
                    "lat": 41.4036,
                    "lng": 2.1744
                }
            },
            {
                "city": "Barcelona",
                "days": "Day 2",
                "description": "Explore the Gothic Quarter, one of the oldest parts of the city, known for its narrow medieval streets and historic buildings. Visit the Barcelona Cathedral and the Plaça del Rei. In the afternoon, head to Park Güell, another of Gaudí's masterpieces, and enjoy panoramic views of the city.",
                "highlights": [
                    "Gothic Quarter: Historic area with narrow medieval streets",
                    "Barcelona Cathedral: Stunning Gothic cathedral",
                    "Plaça del Rei: Historic square with royal palace",
                    "Park Güell: Gaudí-designed park with beautiful city views"
                ],
                "coordinates": {
                    "lat": 41.4145,
                    "lng": 2.1527
                }
            },
            {
                "city": "Barcelona",
                "days": "Day 3",
                "description": "Visit the Picasso Museum to see one of the most extensive collections of artworks by the 20th-century Spanish artist Pablo Picasso. In the afternoon, explore the trendy El Born neighborhood, known for its boutiques, cafes, and the beautiful Basilica of Santa Maria del Mar. End the day with a walk along the beach at Barceloneta.",
                "highlights": [
                    "Picasso Museum: Extensive collection of Picasso's artworks",
                    "El Born: Trendy neighborhood with boutiques and cafes",
                    "Basilica of Santa Maria del Mar: Stunning Gothic church",
                    "Barceloneta Beach: Popular beach with a lively atmosphere"
                ],
                "coordinates": {
                    "lat": 41.3809,
                    "lng": 2.1894
                }
            },
            {
                "city": "Barcelona",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to Montjuïc Hill. Explore the Montjuïc Castle and enjoy the views of the city and harbor. Visit the Magic Fountain of Montjuïc for its spectacular evening light and water show. Spend your final evening dining at a local tapas bar and experiencing the vibrant nightlife of Barcelona.",
                "highlights": [
                    "Montjuïc Hill: Historic hill with panoramic views",
                    "Montjuïc Castle: Historic fortress with city and harbor views",
                    "Magic Fountain of Montjuïc: Evening light and water show",
                    "Tapas Bar: Experience local cuisine and vibrant nightlife"
                ],
                "coordinates": {
                    "lat": 41.3561,
                    "lng": 2.1574
                }
            }
        ]
    },
    
    {
        "city": "London",
        "country": "England",
        "image_desc": "Big Ben",
        "image_url": "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg",
        "itinerary_title": "Discover the Historic and Modern Wonders of London",
        "trip_duration": "5 days 4 nights",
        "price": 1500,
        "group_size": "2 people",
        "route": "London",
        "description": "London, the capital city of England, is a vibrant metropolis that blends historic landmarks with modern attractions. Known for its rich history, diverse culture, and world-renowned sights, London offers something for every traveler. This itinerary captures the essence of London, from iconic monuments to charming neighborhoods.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "London",
                "days": "Day 1",
                "description": "Start your London adventure with a visit to the Tower of London, home to the Crown Jewels. Explore the historic fortress and learn about its intriguing history. In the afternoon, take a short walk to Tower Bridge and enjoy the views from its high-level walkways. End the day with a visit to the nearby Borough Market for a taste of local food.",
                "highlights": [
                    "Tower of London: Historic castle housing the Crown Jewels",
                    "Tower Bridge: Iconic bridge with stunning views",
                    "Borough Market: Bustling market with a variety of food stalls"
                ],
                "coordinates": {
                    "lat": 51.5081,
                    "lng": -0.0759
                }
            },
            {
                "city": "London",
                "days": "Day 2",
                "description": "Discover the political heart of London with a visit to the Houses of Parliament and Big Ben. Cross Westminster Bridge to visit the London Eye for panoramic views of the city. In the afternoon, explore Westminster Abbey, the site of many royal ceremonies. Finish the day with a stroll through St. James's Park to Buckingham Palace.",
                "highlights": [
                    "Houses of Parliament: Iconic government building with Big Ben",
                    "London Eye: Ferris wheel offering city views",
                    "Westminster Abbey: Historic church with royal connections",
                    "Buckingham Palace: Official residence of the British monarch"
                ],
                "coordinates": {
                    "lat": 51.5007,
                    "lng": -0.1246
                }
            },
            {
                "city": "London",
                "days": "Day 3",
                "description": "Spend the day exploring the world-class museums in South Kensington. Start with the Natural History Museum, then move on to the Victoria and Albert Museum, and finally the Science Museum. In the evening, visit Covent Garden for shopping, dining, and street performances.",
                "highlights": [
                    "Natural History Museum: Museum with exhibits on natural history",
                    "Victoria and Albert Museum: Museum of decorative arts and design",
                    "Science Museum: Interactive exhibits on science and technology",
                    "Covent Garden: Lively area with shops, restaurants, and street performers"
                ],
                "coordinates": {
                    "lat": 51.4967,
                    "lng": -0.1764
                }
            },
            {
                "city": "London",
                "days": "Day 4",
                "description": "Visit the British Museum to see its extensive collection of art and antiquities. In the afternoon, take a walk through the trendy Soho district, known for its vibrant atmosphere and eclectic mix of shops and cafes. End the day with a visit to the West End to see a world-famous theater production.",
                "highlights": [
                    "British Museum: Museum with extensive art and antiquities",
                    "Soho: Trendy district with shops and cafes",
                    "West End: Famous theater district with world-class shows"
                ],
                "coordinates": {
                    "lat": 51.5194,
                    "lng": -0.1270
                }
            },
            {
                "city": "London",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the vibrant Camden Market, known for its alternative fashion and food stalls. In the afternoon, take a boat ride along the Regent's Canal to Little Venice. Finish your London adventure with a relaxing evening in the beautiful Regent's Park.",
                "highlights": [
                    "Camden Market: Market with alternative fashion and food",
                    "Regent's Canal: Scenic canal with boat rides",
                    "Little Venice: Picturesque area with canals and cafes",
                    "Regent's Park: Large park with gardens and open spaces"
                ],
                "coordinates": {
                    "lat": 51.5416,
                    "lng": -0.1459
                }
            }
        ]
    },
    
    {
        "city": "Dubai",
        "country": "UAE",
        "image_desc": "Burj Khalifa",
        "image_url": "https://images.pexels.com/photos/2218885/pexels-photo-2218885.jpeg",
        "itinerary_title": "Experience the Extravagance of Dubai",
        "trip_duration": "5 days 4 nights",
        "price": 1600,
        "group_size": "2 people",
        "route": "Dubai",
        "description": "Dubai, a city known for its futuristic architecture, luxury shopping, and vibrant nightlife, offers a blend of modern attractions and cultural experiences. This itinerary captures the essence of Dubai, from its iconic skyscrapers to its traditional souks.",
        "available_months": "November - April",
        "itinerary": [
            {
                "city": "Dubai",
                "days": "Day 1",
                "description": "Begin your Dubai adventure with a visit to the Burj Khalifa, the world's tallest building. Enjoy the breathtaking views from the observation deck. In the afternoon, explore The Dubai Mall, one of the largest shopping malls in the world, and visit the Dubai Aquarium and Underwater Zoo. End your day with the Dubai Fountain show.",
                "highlights": [
                    "Burj Khalifa: World's tallest building with observation deck",
                    "The Dubai Mall: Extensive shopping mall with various attractions",
                    "Dubai Aquarium and Underwater Zoo: Large indoor aquarium",
                    "Dubai Fountain: Spectacular water show"
                ],
                "coordinates": {
                    "lat": 25.1972,
                    "lng": 55.2744
                }
            },
            {
                "city": "Dubai",
                "days": "Day 2",
                "description": "Experience the cultural side of Dubai with a visit to the Al Fahidi Historic District. Explore the Dubai Museum housed in the Al Fahidi Fort and take an abra ride across Dubai Creek to visit the Gold and Spice Souks. In the evening, enjoy a traditional dinner on a Dhow Cruise.",
                "highlights": [
                    "Al Fahidi Historic District: Area with traditional architecture",
                    "Dubai Museum: Museum showcasing Dubai's history",
                    "Abra Ride: Traditional boat ride across Dubai Creek",
                    "Gold and Spice Souks: Traditional markets with gold and spices",
                    "Dhow Cruise: Traditional boat dinner cruise"
                ],
                "coordinates": {
                    "lat": 25.2637,
                    "lng": 55.2972
                }
            },
            {
                "city": "Dubai",
                "days": "Day 3",
                "description": "Spend the day at Jumeirah Beach, relaxing on the sandy shores and swimming in the warm waters. Visit the iconic Burj Al Arab, the luxurious hotel known for its distinctive sail-shaped silhouette. In the afternoon, head to the Palm Jumeirah and visit the Atlantis, The Palm resort. Explore the Aquaventure Waterpark or the Lost Chambers Aquarium.",
                "highlights": [
                    "Jumeirah Beach: Popular beach with beautiful views",
                    "Burj Al Arab: Iconic luxury hotel",
                    "Palm Jumeirah: Man-made island with luxury resorts",
                    "Atlantis, The Palm: Resort with waterpark and aquarium"
                ],
                "coordinates": {
                    "lat": 25.1414,
                    "lng": 55.1853
                }
            },
            {
                "city": "Dubai",
                "days": "Day 4",
                "description": "Embark on a desert safari adventure. Enjoy activities like dune bashing, camel riding, and sandboarding. Experience a traditional Bedouin camp with henna painting, falconry, and a belly dance performance. Enjoy a barbecue dinner under the stars.",
                "highlights": [
                    "Desert Safari: Adventure with dune bashing and camel riding",
                    "Bedouin Camp: Traditional camp with cultural activities",
                    "Barbecue Dinner: Dinner under the stars with entertainment"
                ],
                "coordinates": {
                    "lat": 24.9877,
                    "lng": 55.2962
                }
            },
            {
                "city": "Dubai",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Dubai Marina, known for its stunning skyline and waterfront dining. Take a leisurely walk along the Marina Walk and enjoy the views. In the afternoon, visit the Miracle Garden, a unique floral garden with intricate designs. End your day with a visit to the Global Village, a multicultural festival park offering entertainment, shopping, and dining from around the world.",
                "highlights": [
                    "Dubai Marina: Waterfront area with stunning skyline",
                    "Marina Walk: Scenic walkway along the waterfront",
                    "Miracle Garden: Unique floral garden with intricate designs",
                    "Global Village: Multicultural festival park"
                ],
                "coordinates": {
                    "lat": 25.0760,
                    "lng": 55.1339
                }
            }
        ]
    },
    
    {
        "city": "Istanbul",
        "country": "Turkey",
        "image_desc": "Hagia Sophia",
        "image_url": "https://images.pexels.com/photos/1611046/pexels-photo-1611046.jpeg",
        "itinerary_title": "Discover the Historic and Vibrant City of Istanbul",
        "trip_duration": "5 days 4 nights",
        "price": 1300,
        "group_size": "2 people",
        "route": "Istanbul",
        "description": "Istanbul, where East meets West, is a city that boasts a rich history and vibrant culture. With its stunning mosques, bustling bazaars, and beautiful Bosphorus views, Istanbul offers a unique travel experience. This itinerary captures the essence of Istanbul, from its ancient landmarks to its modern attractions.",
        "available_months": "April - June, September - November",
        "itinerary": [
            {
                "city": "Istanbul",
                "days": "Day 1",
                "description": "Begin your Istanbul adventure with a visit to the Hagia Sophia, a magnificent architectural marvel that has served as a church, mosque, and museum. Explore the nearby Blue Mosque, known for its stunning blue tiles and grand domes. In the afternoon, visit the Basilica Cistern, an ancient underground water reservoir.",
                "highlights": [
                    "Hagia Sophia: Historic architectural marvel",
                    "Blue Mosque: Iconic mosque with stunning blue tiles",
                    "Basilica Cistern: Ancient underground water reservoir"
                ],
                "coordinates": {
                    "lat": 41.0086,
                    "lng": 28.9802
                }
            },
            {
                "city": "Istanbul",
                "days": "Day 2",
                "description": "Spend the day exploring the Topkapi Palace, the former residence of Ottoman sultans. Discover its lavish rooms, beautiful courtyards, and impressive collections. In the afternoon, visit the Istanbul Archaeology Museums, housing a vast collection of artifacts. End the day with a stroll through the historic Sultanahmet Square.",
                "highlights": [
                    "Topkapi Palace: Lavish palace with beautiful courtyards",
                    "Istanbul Archaeology Museums: Extensive collection of artifacts",
                    "Sultanahmet Square: Historic square with iconic landmarks"
                ],
                "coordinates": {
                    "lat": 41.0128,
                    "lng": 28.9837
                }
            },
            {
                "city": "Istanbul",
                "days": "Day 3",
                "description": "Visit the Grand Bazaar, one of the largest and oldest covered markets in the world. Shop for unique souvenirs, jewelry, and spices. In the afternoon, explore the vibrant Spice Bazaar and enjoy the colorful stalls and aromatic scents. Take a Bosphorus cruise in the evening to see the city from the water.",
                "highlights": [
                    "Grand Bazaar: Large historic covered market",
                    "Spice Bazaar: Vibrant market with spices and goods",
                    "Bosphorus Cruise: Scenic cruise with city views"
                ],
                "coordinates": {
                    "lat": 41.0130,
                    "lng": 28.9680
                }
            },
            {
                "city": "Istanbul",
                "days": "Day 4",
                "description": "Discover the artistic side of Istanbul with a visit to the Istanbul Modern Art Museum. In the afternoon, explore the trendy Galata district, known for its historic tower and lively atmosphere. Visit the Galata Tower for panoramic views of the city and enjoy a walk through Istiklal Street.",
                "highlights": [
                    "Istanbul Modern Art Museum: Museum showcasing contemporary art",
                    "Galata District: Trendy area with historic tower",
                    "Galata Tower: Tower with panoramic city views",
                    "Istiklal Street: Lively street with shops and cafes"
                ],
                "coordinates": {
                    "lat": 41.0256,
                    "lng": 28.9744
                }
            },
            {
                "city": "Istanbul",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Dolmabahce Palace, a stunning waterfront palace that served as the main administrative center of the Ottoman Empire. In the afternoon, relax in the beautiful gardens of Yildiz Park. End your day with a traditional Turkish dinner and experience a Whirling Dervishes performance.",
                "highlights": [
                    "Dolmabahce Palace: Stunning waterfront palace",
                    "Yildiz Park: Beautiful gardens for relaxation",
                    "Traditional Turkish Dinner: Experience local cuisine",
                    "Whirling Dervishes Performance: Unique cultural experience"
                ],
                "coordinates": {
                    "lat": 41.0390,
                    "lng": 29.0006
                }
            }
        ]
    },
    
    {
        "city": "Bangkok",
        "country": "Thailand",
        "image_desc": "Wat Arun",
        "image_url": "https://images.pexels.com/photos/2071650/pexels-photo-2071650.jpeg",
        "itinerary_title": "Explore the Vibrant Culture of Bangkok",
        "trip_duration": "5 days 4 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Bangkok",
        "description": "Bangkok, the bustling capital of Thailand, is known for its vibrant street life, opulent temples, and rich cultural heritage. This itinerary provides a comprehensive exploration of Bangkok, from its historic landmarks to its modern attractions.",
        "available_months": "November - February",
        "itinerary": [
            {
                "city": "Bangkok",
                "days": "Day 1",
                "description": "Start your Bangkok adventure with a visit to the Grand Palace, the official residence of the Kings of Siam. Explore the adjacent Wat Phra Kaew, known for the Emerald Buddha. In the afternoon, visit Wat Pho, home to the famous reclining Buddha, and end the day with a traditional Thai massage.",
                "highlights": [
                    "Grand Palace: Official residence of the Kings of Siam",
                    "Wat Phra Kaew: Temple of the Emerald Buddha",
                    "Wat Pho: Temple with the famous reclining Buddha",
                    "Traditional Thai Massage: Relaxing massage experience"
                ],
                "coordinates": {
                    "lat": 13.7500,
                    "lng": 100.4913
                }
            },
            {
                "city": "Bangkok",
                "days": "Day 2",
                "description": "Visit the iconic Wat Arun, also known as the Temple of Dawn, located on the west bank of the Chao Phraya River. In the afternoon, explore the bustling Chatuchak Weekend Market, one of the largest markets in the world. Spend the evening at Asiatique The Riverfront, a night market and dining destination along the river.",
                "highlights": [
                    "Wat Arun: Iconic temple on the Chao Phraya River",
                    "Chatuchak Weekend Market: One of the world's largest markets",
                    "Asiatique The Riverfront: Night market and dining destination"
                ],
                "coordinates": {
                    "lat": 13.7437,
                    "lng": 100.4881
                }
            },
            {
                "city": "Bangkok",
                "days": "Day 3",
                "description": "Take a day trip to the Damnoen Saduak Floating Market, located about 100 km southwest of Bangkok. Experience the vibrant atmosphere and shop from boats selling fresh produce and local goods. In the afternoon, visit the Maeklong Railway Market, famous for its stalls set up on active train tracks.",
                "highlights": [
                    "Damnoen Saduak Floating Market: Vibrant floating market",
                    "Maeklong Railway Market: Market on active train tracks"
                ],
                "coordinates": {
                    "lat": 13.5171,
                    "lng": 99.9561
                }
            },
            {
                "city": "Bangkok",
                "days": "Day 4",
                "description": "Explore the modern side of Bangkok with a visit to the Siam area. Start with the Jim Thompson House, a museum dedicated to the American businessman and art collector. Spend the afternoon shopping at the upscale Siam Paragon and MBK Center. End the day with a visit to the Erawan Shrine, a popular Hindu shrine.",
                "highlights": [
                    "Jim Thompson House: Museum of art and culture",
                    "Siam Paragon: Upscale shopping mall",
                    "MBK Center: Popular shopping destination",
                    "Erawan Shrine: Popular Hindu shrine"
                ],
                "coordinates": {
                    "lat": 13.7465,
                    "lng": 100.5347
                }
            },
            {
                "city": "Bangkok",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Bangkok Art and Culture Centre, showcasing contemporary art. In the afternoon, relax at Lumpini Park, an urban park in the heart of the city. Finish your Bangkok adventure with a dinner cruise on the Chao Phraya River, enjoying the city's skyline and landmarks illuminated at night.",
                "highlights": [
                    "Bangkok Art and Culture Centre: Contemporary art museum",
                    "Lumpini Park: Urban park with green spaces and lakes",
                    "Chao Phraya River Dinner Cruise: Evening cruise with city views"
                ],
                "coordinates": {
                    "lat": 13.7306,
                    "lng": 100.5412
                }
            }
        ]
    },
    
    {
        "city": "Sydney",
        "country": "Australia",
        "image_desc": "Sydney Opera House",
        "image_url": "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg",
        "itinerary_title": "Experience the Best of Sydney",
        "trip_duration": "5 days 4 nights",
        "price": 1500,
        "group_size": "2 people",
        "route": "Sydney",
        "description": "Sydney, Australia's largest city, is known for its stunning harbor, iconic landmarks, and vibrant cultural scene. This itinerary offers a mix of natural beauty, historic sites, and modern attractions, capturing the essence of Sydney.",
        "available_months": "September - November, March - May",
        "itinerary": [
            {
                "city": "Sydney",
                "days": "Day 1",
                "description": "Start your Sydney adventure with a visit to the Sydney Opera House, an architectural marvel and a UNESCO World Heritage site. Take a guided tour of the iconic building. In the afternoon, stroll through the Royal Botanic Garden and enjoy the stunning views of Sydney Harbour. End the day with dinner at Circular Quay.",
                "highlights": [
                    "Sydney Opera House: Iconic performing arts center",
                    "Royal Botanic Garden: Beautiful gardens with harbor views",
                    "Circular Quay: Vibrant area with dining and entertainment"
                ],
                "coordinates": {
                    "lat": -33.8568,
                    "lng": 151.2153
                }
            },
            {
                "city": "Sydney",
                "days": "Day 2",
                "description": "Explore the historic Rocks district, known for its cobblestone streets and colonial buildings. Visit the Rocks Market and the Museum of Contemporary Art. In the afternoon, take a ferry to Taronga Zoo and enjoy the views of the city skyline while seeing a wide variety of animals.",
                "highlights": [
                    "The Rocks: Historic district with shops and cafes",
                    "Rocks Market: Weekend market with local crafts and food",
                    "Museum of Contemporary Art: Gallery with modern artworks",
                    "Taronga Zoo: Zoo with diverse animal exhibits and city views"
                ],
                "coordinates": {
                    "lat": -33.8587,
                    "lng": 151.2093
                }
            },
            {
                "city": "Sydney",
                "days": "Day 3",
                "description": "Visit Bondi Beach, one of Australia's most famous beaches. Enjoy the sun, surf, and sand, and take the Bondi to Coogee coastal walk for stunning ocean views. In the evening, explore Darling Harbour with its numerous restaurants, shops, and entertainment options.",
                "highlights": [
                    "Bondi Beach: Famous beach known for surfing and sunbathing",
                    "Bondi to Coogee Walk: Scenic coastal walk with ocean views",
                    "Darling Harbour: Vibrant area with dining and entertainment"
                ],
                "coordinates": {
                    "lat": -33.8908,
                    "lng": 151.2743
                }
            },
            {
                "city": "Sydney",
                "days": "Day 4",
                "description": "Spend the day at the Sydney Harbour Bridge. Climb the bridge for panoramic views of the city or walk across it for a different perspective. Visit the nearby Luna Park for some fun rides and activities. In the evening, dine at one of the waterfront restaurants in the area.",
                "highlights": [
                    "Sydney Harbour Bridge: Iconic bridge with climb and walk options",
                    "Luna Park: Amusement park with rides and games",
                    "Waterfront Dining: Enjoy dinner with harbor views"
                ],
                "coordinates": {
                    "lat": -33.8523,
                    "lng": 151.2108
                }
            },
            {
                "city": "Sydney",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Art Gallery of New South Wales, featuring a diverse collection of art. In the afternoon, explore the Queen Victoria Building, a historic shopping center with beautiful architecture. Finish your Sydney adventure with a sunset cruise on Sydney Harbour, enjoying the stunning views of the city skyline.",
                "highlights": [
                    "Art Gallery of New South Wales: Art museum with diverse collections",
                    "Queen Victoria Building: Historic shopping center with elegant architecture",
                    "Sydney Harbour Sunset Cruise: Evening cruise with stunning city views"
                ],
                "coordinates": {
                    "lat": -33.8688,
                    "lng": 151.2093
                }
            }
        ]
    },
    
    {
        "city": "Moscow",
        "country": "Russia",
        "image_desc": "Red Square",
        "image_url": "https://images.pexels.com/photos/672532/pexels-photo-672532.jpeg",
        "itinerary_title": "Explore the Historic and Modern Wonders of Moscow",
        "trip_duration": "5 days 4 nights",
        "price": 1400,
        "group_size": "2 people",
        "route": "Moscow",
        "description": "Moscow, the capital of Russia, is a city rich in history, culture, and architectural marvels. This itinerary takes you through the iconic landmarks, vibrant neighborhoods, and cultural treasures of Moscow, offering a comprehensive experience of this dynamic city.",
        "available_months": "May - September",
        "itinerary": [
            {
                "city": "Moscow",
                "days": "Day 1",
                "description": "Start your Moscow adventure with a visit to the iconic Red Square. Explore the Kremlin and its impressive cathedrals, and visit the famous St. Basil's Cathedral with its colorful onion domes. In the evening, take a walk along the lively Arbat Street, known for its shops, cafes, and street performances.",
                "highlights": [
                    "Red Square: Historic square at the heart of Moscow",
                    "Kremlin: Fortress complex with cathedrals and museums",
                    "St. Basil's Cathedral: Iconic cathedral with colorful domes",
                    "Arbat Street: Vibrant street with shops and cafes"
                ],
                "coordinates": {
                    "lat": 55.7539,
                    "lng": 37.6208
                }
            },
            {
                "city": "Moscow",
                "days": "Day 2",
                "description": "Visit the State Tretyakov Gallery, which houses an extensive collection of Russian art. In the afternoon, explore the Cathedral of Christ the Saviour, the tallest Orthodox Christian church in the world. End your day with a relaxing boat cruise on the Moskva River, enjoying the city views from the water.",
                "highlights": [
                    "State Tretyakov Gallery: Art museum with Russian artworks",
                    "Cathedral of Christ the Saviour: Tallest Orthodox Christian church",
                    "Moskva River Cruise: Scenic boat ride with city views"
                ],
                "coordinates": {
                    "lat": 55.7414,
                    "lng": 37.6209
                }
            },
            {
                "city": "Moscow",
                "days": "Day 3",
                "description": "Discover the beauty of Gorky Park, a popular recreational area with gardens, lakes, and cultural spaces. Visit the Garage Museum of Contemporary Art located within the park. In the afternoon, explore the impressive Moscow Metro stations, known for their stunning architecture and design.",
                "highlights": [
                    "Gorky Park: Recreational park with gardens and cultural spaces",
                    "Garage Museum of Contemporary Art: Museum within Gorky Park",
                    "Moscow Metro Stations: Famous for their architectural beauty"
                ],
                "coordinates": {
                    "lat": 55.7297,
                    "lng": 37.6033
                }
            },
            {
                "city": "Moscow",
                "days": "Day 4",
                "description": "Spend the day at the Moscow State University and the Sparrow Hills. Enjoy the panoramic views of the city from the observation deck. In the afternoon, visit the Novodevichy Convent, a UNESCO World Heritage site with beautiful churches and gardens. In the evening, enjoy a performance at the Bolshoi Theatre.",
                "highlights": [
                    "Moscow State University: Historic university with panoramic views",
                    "Sparrow Hills: Observation deck with city views",
                    "Novodevichy Convent: Historic convent with beautiful churches",
                    "Bolshoi Theatre: World-renowned theatre for ballet and opera"
                ],
                "coordinates": {
                    "lat": 55.7904,
                    "lng": 37.6120
                }
            },
            {
                "city": "Moscow",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Izmailovsky Market, a great place to shop for souvenirs, crafts, and antiques. In the afternoon, explore the Tsaritsyno Park and Palace, a beautiful historic estate with stunning gardens and architectural marvels. End your Moscow adventure with a dinner at a traditional Russian restaurant.",
                "highlights": [
                    "Izmailovsky Market: Market for souvenirs and antiques",
                    "Tsaritsyno Park and Palace: Historic estate with gardens",
                    "Traditional Russian Dinner: Experience local cuisine"
                ],
                "coordinates": {
                    "lat": 55.6214,
                    "lng": 37.6688
                }
            }
        ]
    },
    
    {
        "city": "Beijing",
        "country": "China",
        "image_desc": "Great Wall of China",
        "image_url": "https://images.pexels.com/photos/258606/pexels-photo-258606.jpeg",
        "itinerary_title": "Discover the Rich Heritage of Beijing",
        "trip_duration": "5 days 4 nights",
        "price": 1300,
        "group_size": "2 people",
        "route": "Beijing",
        "description": "Beijing, the capital of China, is a city steeped in history and culture. Known for its ancient landmarks, vibrant neighborhoods, and exquisite cuisine, Beijing offers an unforgettable travel experience. This itinerary covers the must-see attractions and hidden gems of Beijing.",
        "available_months": "April - June, September - November",
        "itinerary": [
            {
                "city": "Beijing",
                "days": "Day 1",
                "description": "Begin your Beijing adventure with a visit to the iconic Tiananmen Square, one of the largest public squares in the world. Explore the Forbidden City, the largest ancient palatial structure in the world. In the evening, take a stroll through Wangfujing Street, known for its shopping and street food.",
                "highlights": [
                    "Tiananmen Square: Historic public square",
                    "Forbidden City: Ancient palatial complex",
                    "Wangfujing Street: Famous shopping and dining street"
                ],
                "coordinates": {
                    "lat": 39.9042,
                    "lng": 116.4074
                }
            },
            {
                "city": "Beijing",
                "days": "Day 2",
                "description": "Visit the Temple of Heaven, a beautiful complex of religious buildings used by emperors for annual ceremonies of prayer to Heaven for good harvest. In the afternoon, explore the Summer Palace, a vast ensemble of lakes, gardens, and palaces. Enjoy a boat ride on Kunming Lake.",
                "highlights": [
                    "Temple of Heaven: Historic religious complex",
                    "Summer Palace: Stunning imperial garden",
                    "Kunming Lake: Scenic lake with boat rides"
                ],
                "coordinates": {
                    "lat": 39.8822,
                    "lng": 116.4065
                }
            },
            {
                "city": "Beijing",
                "days": "Day 3",
                "description": "Take a day trip to the Great Wall of China at Mutianyu, one of the best-preserved and most scenic sections of the wall. Spend the day hiking and exploring the ancient fortifications. In the evening, return to Beijing and enjoy a traditional Peking duck dinner.",
                "highlights": [
                    "Great Wall of China: Iconic ancient fortification",
                    "Mutianyu: Scenic section of the Great Wall",
                    "Peking Duck Dinner: Traditional Beijing cuisine"
                ],
                "coordinates": {
                    "lat": 40.4319,
                    "lng": 116.5704
                }
            },
            {
                "city": "Beijing",
                "days": "Day 4",
                "description": "Visit the Lama Temple, one of the largest and most important Tibetan Buddhist monasteries in the world. In the afternoon, explore the 798 Art District, a thriving artistic community with galleries, cafes, and shops. End the day with a walk through the Hutongs, traditional alleyways that offer a glimpse into Beijing's past.",
                "highlights": [
                    "Lama Temple: Important Tibetan Buddhist monastery",
                    "798 Art District: Vibrant artistic community",
                    "Hutongs: Traditional alleyways with historic charm"
                ],
                "coordinates": {
                    "lat": 39.9480,
                    "lng": 116.4164
                }
            },
            {
                "city": "Beijing",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the National Museum of China, one of the largest museums in the world, showcasing Chinese history and art. In the afternoon, relax at Beihai Park, an imperial garden with beautiful landscapes and historic structures. Finish your Beijing adventure with a dinner in the trendy Sanlitun area.",
                "highlights": [
                    "National Museum of China: Extensive museum of Chinese history and art",
                    "Beihai Park: Imperial garden with scenic beauty",
                    "Sanlitun: Trendy area with dining and nightlife"
                ],
                "coordinates": {
                    "lat": 39.9139,
                    "lng": 116.3917
                }
            }
        ]
    },
    
    {
        "city": "Cairo",
        "country": "Egypt",
        "image_desc": "Pyramids of Giza",
        "image_url": "https://images.pexels.com/photos/248771/pexels-photo-248771.jpeg",
        "itinerary_title": "Explore the Ancient Wonders of Cairo",
        "trip_duration": "5 days 4 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Cairo",
        "description": "Cairo, the capital of Egypt, is a city that blends ancient history with vibrant modernity. Known for its iconic pyramids, bustling bazaars, and rich cultural heritage, Cairo offers a unique travel experience. This itinerary covers the must-see attractions and hidden gems of Cairo.",
        "available_months": "October - April",
        "itinerary": [
            {
                "city": "Cairo",
                "days": "Day 1",
                "description": "Begin your Cairo adventure with a visit to the Egyptian Museum, home to an extensive collection of ancient Egyptian artifacts, including the treasures of Tutankhamun. In the afternoon, explore Tahrir Square and its surroundings. In the evening, enjoy a traditional Egyptian dinner on a Nile River cruise.",
                "highlights": [
                    "Egyptian Museum: Extensive collection of ancient artifacts",
                    "Tahrir Square: Historic square in the heart of Cairo",
                    "Nile River Cruise: Dinner cruise with city views"
                ],
                "coordinates": {
                    "lat": 30.0444,
                    "lng": 31.2357
                }
            },
            {
                "city": "Cairo",
                "days": "Day 2",
                "description": "Visit the Giza Plateau to see the iconic Pyramids of Giza and the Great Sphinx. Take a guided tour to learn about the history and significance of these ancient wonders. In the afternoon, visit the Solar Boat Museum and explore the surrounding area.",
                "highlights": [
                    "Pyramids of Giza: Ancient wonders of the world",
                    "Great Sphinx: Iconic limestone statue",
                    "Solar Boat Museum: Ancient Egyptian boat museum"
                ],
                "coordinates": {
                    "lat": 29.9792,
                    "lng": 31.1342
                }
            },
            {
                "city": "Cairo",
                "days": "Day 3",
                "description": "Discover Islamic Cairo with a visit to the Citadel of Saladin and the beautiful Mosque of Muhammad Ali. Explore the historic Al-Azhar Mosque and the bustling Khan El Khalili bazaar, known for its traditional crafts and souvenirs.",
                "highlights": [
                    "Citadel of Saladin: Historic fortress with city views",
                    "Mosque of Muhammad Ali: Stunning mosque with grand architecture",
                    "Al-Azhar Mosque: Historic mosque and university",
                    "Khan El Khalili: Bustling bazaar with traditional crafts"
                ],
                "coordinates": {
                    "lat": 30.0299,
                    "lng": 31.2615
                }
            },
            {
                "city": "Cairo",
                "days": "Day 4",
                "description": "Take a day trip to Saqqara to see the Step Pyramid of Djoser, one of the earliest colossal stone buildings in Egypt. Visit the nearby Dahshur area to see the Bent Pyramid and the Red Pyramid. Return to Cairo in the evening and relax at Al-Azhar Park, enjoying the beautiful gardens and city views.",
                "highlights": [
                    "Step Pyramid of Djoser: Early colossal stone building",
                    "Bent Pyramid: Unique pyramid with a bent angle",
                    "Red Pyramid: One of the largest pyramids in Egypt",
                    "Al-Azhar Park: Beautiful gardens with city views"
                ],
                "coordinates": {
                    "lat": 29.8713,
                    "lng": 31.2165
                }
            },
            {
                "city": "Cairo",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Coptic Cairo area. Explore the Hanging Church, the Coptic Museum, and the Ben Ezra Synagogue. In the afternoon, visit the vibrant Zamalek district, known for its cultural venues and cafes. End your Cairo adventure with a farewell dinner at a local restaurant.",
                "highlights": [
                    "Hanging Church: Historic Coptic church",
                    "Coptic Museum: Museum with extensive Coptic artifacts",
                    "Ben Ezra Synagogue: Historic synagogue",
                    "Zamalek: Cultural district with cafes and venues"
                ],
                "coordinates": {
                    "lat": 30.0459,
                    "lng": 31.2243
                }
            }
        ]
    },
    
    {
        "city": "Rio de Janeiro",
        "country": "Brazil",
        "image_desc": "Christ the Redeemer",
        "image_url": "https://images.pexels.com/photos/3244513/pexels-photo-3244513.jpeg",
        "itinerary_title": "Experience the Vibrant Energy of Rio de Janeiro",
        "trip_duration": "5 days 4 nights",
        "price": 1350,
        "group_size": "2 people",
        "route": "Rio de Janeiro",
        "description": "Rio de Janeiro, known for its stunning beaches, lively culture, and iconic landmarks, offers an unforgettable travel experience. This itinerary captures the essence of Rio, from its famous beaches to its historic sites and vibrant neighborhoods.",
        "available_months": "March - November",
        "itinerary": [
            {
                "city": "Rio de Janeiro",
                "days": "Day 1",
                "description": "Start your Rio adventure with a visit to the iconic Christ the Redeemer statue on Corcovado Mountain. Enjoy the panoramic views of the city. In the afternoon, explore the lush Tijuca National Park, home to diverse wildlife and beautiful waterfalls. End your day with a relaxing evening at Copacabana Beach.",
                "highlights": [
                    "Christ the Redeemer: Iconic statue with panoramic city views",
                    "Tijuca National Park: Lush rainforest with wildlife and waterfalls",
                    "Copacabana Beach: Famous beach with a lively atmosphere"
                ],
                "coordinates": {
                    "lat": -22.9519,
                    "lng": -43.2105
                }
            },
            {
                "city": "Rio de Janeiro",
                "days": "Day 2",
                "description": "Spend the morning at Sugarloaf Mountain, taking the cable car to the summit for stunning views of Rio and the surrounding ocean. In the afternoon, visit the historic neighborhood of Santa Teresa, known for its narrow streets, colonial houses, and vibrant art scene. Enjoy dinner at a local restaurant.",
                "highlights": [
                    "Sugarloaf Mountain: Cable car ride with stunning views",
                    "Santa Teresa: Historic neighborhood with colonial houses and art scene",
                    "Local Restaurant: Enjoy traditional Brazilian cuisine"
                ],
                "coordinates": {
                    "lat": -22.9486,
                    "lng": -43.1565
                }
            },
            {
                "city": "Rio de Janeiro",
                "days": "Day 3",
                "description": "Explore the vibrant neighborhood of Lapa, famous for its nightlife and the iconic Selarón Steps, a colorful mosaic staircase. Visit the nearby Metropolitan Cathedral of Rio de Janeiro with its unique modernist design. In the evening, experience the lively samba music and dance at a local club.",
                "highlights": [
                    "Selarón Steps: Colorful mosaic staircase",
                    "Metropolitan Cathedral: Modernist cathedral with unique design",
                    "Lapa: Vibrant neighborhood with lively nightlife"
                ],
                "coordinates": {
                    "lat": -22.9133,
                    "lng": -43.1825
                }
            },
            {
                "city": "Rio de Janeiro",
                "days": "Day 4",
                "description": "Take a day trip to Ilha Grande, an island paradise known for its pristine beaches, clear waters, and lush rainforests. Enjoy snorkeling, hiking, and relaxing on the beach. Return to Rio in the evening and dine at a beachside restaurant in Ipanema.",
                "highlights": [
                    "Ilha Grande: Island with pristine beaches and clear waters",
                    "Snorkeling and Hiking: Enjoy outdoor activities on the island",
                    "Ipanema Beach: Famous beach with beautiful sunset views"
                ],
                "coordinates": {
                    "lat": -23.1471,
                    "lng": -44.1705
                }
            },
            {
                "city": "Rio de Janeiro",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Botanical Garden of Rio de Janeiro, featuring a diverse collection of plants and beautiful landscapes. In the afternoon, explore the Museum of Tomorrow, an innovative science museum with interactive exhibits. End your Rio adventure with a sunset at Arpoador, a rocky peninsula offering stunning views of the coastline.",
                "highlights": [
                    "Botanical Garden: Beautiful garden with diverse plant collections",
                    "Museum of Tomorrow: Innovative science museum with interactive exhibits",
                    "Arpoador: Rocky peninsula with stunning sunset views"
                ],
                "coordinates": {
                    "lat": -22.9711,
                    "lng": -43.2230
                }
            }
        ]
    },

    {
        "city": "Mexico City",
        "country": "Mexico",
        "itinerary_title": "Discover the Heart of Mexico City",
        "trip_duration": "5 days 4 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Mexico City",
        "description": "Mexico City, the vibrant capital of Mexico, is known for its rich history, cultural landmarks, and delicious cuisine. This itinerary provides a comprehensive exploration of Mexico City, from its ancient ruins to its bustling neighborhoods.",
        "available_months": "October - April",
        "itinerary": [
            {
                "city": "Mexico City",
                "days": "Day 1",
                "description": "Begin your adventure with a visit to the historic Zócalo, the main square of Mexico City. Explore the Metropolitan Cathedral and the National Palace, home to Diego Rivera's famous murals. In the evening, enjoy a traditional Mexican dinner at a local restaurant.",
                "highlights": [
                    "Zócalo: Historic main square of Mexico City",
                    "Metropolitan Cathedral: Largest cathedral in the Americas",
                    "National Palace: Home to Diego Rivera's murals",
                    "Traditional Mexican Dinner: Experience local cuisine"
                ],
                "coordinates": {
                    "lat": 19.4326,
                    "lng": -99.1332
                }
            },
            {
                "city": "Mexico City",
                "days": "Day 2",
                "description": "Visit the ancient ruins of Teotihuacan, known for its massive pyramids, including the Pyramid of the Sun and the Pyramid of the Moon. In the afternoon, return to the city and explore the Basilica of Our Lady of Guadalupe, a major pilgrimage site.",
                "highlights": [
                    "Teotihuacan: Ancient Mesoamerican city with massive pyramids",
                    "Pyramid of the Sun: Largest pyramid in Teotihuacan",
                    "Basilica of Our Lady of Guadalupe: Major pilgrimage site"
                ],
                "coordinates": {
                    "lat": 19.6925,
                    "lng": -98.8438
                }
            },
            {
                "city": "Mexico City",
                "days": "Day 3",
                "description": "Explore Chapultepec Park, one of the largest city parks in the world. Visit Chapultepec Castle, which offers panoramic views of the city, and the National Museum of Anthropology, home to an extensive collection of pre-Columbian artifacts. In the evening, stroll through the trendy neighborhood of La Condesa.",
                "highlights": [
                    "Chapultepec Park: Large city park with various attractions",
                    "Chapultepec Castle: Historic castle with city views",
                    "National Museum of Anthropology: Extensive collection of pre-Columbian artifacts",
                    "La Condesa: Trendy neighborhood with cafes and shops"
                ],
                "coordinates": {
                    "lat": 19.4204,
                    "lng": -99.1815
                }
            },
            {
                "city": "Mexico City",
                "days": "Day 4",
                "description": "Take a day trip to Xochimilco, known for its network of canals and colorful trajineras (boats). Enjoy a boat ride through the canals and experience the lively atmosphere. In the afternoon, visit the Frida Kahlo Museum in the charming neighborhood of Coyoacán.",
                "highlights": [
                    "Xochimilco: Canals with colorful trajineras",
                    "Boat Ride: Enjoy the lively atmosphere on the canals",
                    "Frida Kahlo Museum: Museum dedicated to the famous artist",
                    "Coyoacán: Charming neighborhood with a bohemian vibe"
                ],
                "coordinates": {
                    "lat": 19.2629,
                    "lng": -99.1076
                }
            },
            {
                "city": "Mexico City",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Palacio de Bellas Artes, a stunning cultural center that hosts performances and art exhibitions. In the afternoon, explore the bustling Mercado de La Merced, one of the largest traditional markets in the city. Finish your Mexico City adventure with a farewell dinner at a rooftop restaurant with views of the city skyline.",
                "highlights": [
                    "Palacio de Bellas Artes: Cultural center with performances and exhibitions",
                    "Mercado de La Merced: Large traditional market",
                    "Rooftop Dinner: Enjoy a meal with views of the city skyline"
                ],
                "coordinates": {
                    "lat": 19.4352,
                    "lng": -99.1412
                }
            }
        ]
    },
    
    {
        "city": "Amsterdam",
        "country": "Netherlands",
        "image_desc": "Canals",
        "image_url": "https://images.pexels.com/photos/378570/pexels-photo-378570.jpeg",
        "itinerary_title": "Explore the Charming Canals of Amsterdam",
        "trip_duration": "4 days 3 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Amsterdam",
        "description": "Amsterdam, the capital of the Netherlands, is known for its picturesque canals, historic buildings, and vibrant cultural scene. This itinerary offers a blend of art, history, and relaxation, capturing the essence of Amsterdam.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Amsterdam",
                "days": "Day 1",
                "description": "Begin your Amsterdam adventure with a visit to the Rijksmuseum, home to an extensive collection of Dutch Golden Age paintings. In the afternoon, explore the Van Gogh Museum, dedicated to the works of Vincent van Gogh. End your day with a leisurely stroll through Vondelpark, the largest city park in Amsterdam.",
                "highlights": [
                    "Rijksmuseum: Extensive collection of Dutch Golden Age paintings",
                    "Van Gogh Museum: Museum dedicated to Vincent van Gogh",
                    "Vondelpark: Largest city park in Amsterdam"
                ],
                "coordinates": {
                    "lat": 52.3600,
                    "lng": 4.8852
                }
            },
            {
                "city": "Amsterdam",
                "days": "Day 2",
                "description": "Discover the historic heart of Amsterdam with a visit to the Anne Frank House. Explore the canals with a boat tour to see the city from a different perspective. In the afternoon, visit the Jordaan district, known for its narrow streets, boutique shops, and cozy cafes. Enjoy dinner at a local restaurant.",
                "highlights": [
                    "Anne Frank House: Historic house and museum",
                    "Canal Tour: Boat tour of Amsterdam's canals",
                    "Jordaan District: Charming area with shops and cafes",
                    "Local Restaurant: Enjoy traditional Dutch cuisine"
                ],
                "coordinates": {
                    "lat": 52.3752,
                    "lng": 4.8838
                }
            },
            {
                "city": "Amsterdam",
                "days": "Day 3",
                "description": "Visit the Heineken Experience, an interactive tour of the historic brewery. In the afternoon, explore the vibrant De Pijp neighborhood, known for the Albert Cuyp Market. Spend the evening in the lively Leidseplein area, famous for its nightlife and entertainment options.",
                "highlights": [
                    "Heineken Experience: Interactive brewery tour",
                    "De Pijp: Vibrant neighborhood with Albert Cuyp Market",
                    "Leidseplein: Lively area with nightlife and entertainment"
                ],
                "coordinates": {
                    "lat": 52.3575,
                    "lng": 4.8950
                }
            },
            {
                "city": "Amsterdam",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the historic Dam Square, home to the Royal Palace and the National Monument. In the afternoon, explore the Hermitage Amsterdam, a satellite of the famous Hermitage Museum in St. Petersburg. End your Amsterdam adventure with a relaxing canal-side dinner in the city center.",
                "highlights": [
                    "Dam Square: Historic square with Royal Palace and National Monument",
                    "Hermitage Amsterdam: Museum with rotating exhibits from the Hermitage Museum",
                    "Canal-Side Dinner: Enjoy dinner by the canals"
                ],
                "coordinates": {
                    "lat": 52.3731,
                    "lng": 4.8926
                }
            }
        ]
    },
    
    {
        "city": "Buenos Aires",
        "country": "Argentina",
        "image_desc": "Obelisk",
        "image_url": "https://images.pexels.com/photos/982283/pexels-photo-982283.jpeg",
        "itinerary_title": "Experience the Passion of Buenos Aires",
        "trip_duration": "5 days 4 nights",
        "price": 1300,
        "group_size": "2 people",
        "route": "Buenos Aires",
        "description": "Buenos Aires, the vibrant capital of Argentina, is known for its rich culture, passionate tango, and beautiful architecture. This itinerary offers a blend of historic sites, cultural experiences, and lively neighborhoods, capturing the essence of Buenos Aires.",
        "available_months": "March - May, September - November",
        "itinerary": [
            {
                "city": "Buenos Aires",
                "days": "Day 1",
                "description": "Begin your Buenos Aires adventure with a visit to Plaza de Mayo, the main square of the city. Explore the Casa Rosada, the presidential palace, and the Metropolitan Cathedral. In the evening, enjoy a traditional Argentine dinner at a local parrilla (steakhouse).",
                "highlights": [
                    "Plaza de Mayo: Main square of Buenos Aires",
                    "Casa Rosada: Presidential palace with guided tours",
                    "Metropolitan Cathedral: Historic cathedral",
                    "Parrilla: Traditional Argentine steakhouse"
                ],
                "coordinates": {
                    "lat": -34.6083,
                    "lng": -58.3712
                }
            },
            {
                "city": "Buenos Aires",
                "days": "Day 2",
                "description": "Visit the colorful neighborhood of La Boca, known for its vibrant buildings and tango performances. Explore Caminito Street, an open-air museum and traditional alley. In the afternoon, visit the modern Puerto Madero district and take a stroll along the waterfront.",
                "highlights": [
                    "La Boca: Colorful neighborhood with vibrant buildings",
                    "Caminito Street: Open-air museum and alley",
                    "Puerto Madero: Modern district with waterfront views"
                ],
                "coordinates": {
                    "lat": -34.6345,
                    "lng": -58.3630
                }
            },
            {
                "city": "Buenos Aires",
                "days": "Day 3",
                "description": "Discover the historic San Telmo neighborhood, famous for its cobblestone streets and antique shops. Visit the San Telmo Market and enjoy lunch at a local café. In the evening, experience a tango show at one of the city's renowned tango houses.",
                "highlights": [
                    "San Telmo: Historic neighborhood with cobblestone streets",
                    "San Telmo Market: Market with antiques and local food",
                    "Tango Show: Experience traditional Argentine tango"
                ],
                "coordinates": {
                    "lat": -34.6214,
                    "lng": -58.3739
                }
            },
            {
                "city": "Buenos Aires",
                "days": "Day 4",
                "description": "Spend the day in the upscale Recoleta neighborhood. Visit the Recoleta Cemetery, where many famous Argentines are buried, including Eva Perón. Explore the nearby Recoleta Cultural Center and the National Museum of Fine Arts. In the evening, relax at one of the chic cafes in the area.",
                "highlights": [
                    "Recoleta Cemetery: Historic cemetery with famous graves",
                    "Recoleta Cultural Center: Cultural venue with exhibitions",
                    "National Museum of Fine Arts: Museum with extensive art collections",
                    "Chic Cafes: Relax at upscale cafes"
                ],
                "coordinates": {
                    "lat": -34.5889,
                    "lng": -58.3935
                }
            },
            {
                "city": "Buenos Aires",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to the Palermo neighborhood. Explore the lush Bosques de Palermo, a large park with lakes and rose gardens. Visit the Evita Museum to learn about the life of Eva Perón. End your Buenos Aires adventure with shopping and dining at the trendy Palermo Soho.",
                "highlights": [
                    "Palermo: Trendy neighborhood with parks and cafes",
                    "Bosques de Palermo: Large park with lakes and gardens",
                    "Evita Museum: Museum dedicated to Eva Perón",
                    "Palermo Soho: Area with boutiques and restaurants"
                ],
                "coordinates": {
                    "lat": -34.5747,
                    "lng": -58.4353
                }
            }
        ]
    },

    {
        "city": "Athens",
        "country": "Greece",
        "image_desc": "Acropolis",
        "image_url": "https://images.pexels.com/photos/164248/pexels-photo-164248.jpeg",
        "itinerary_title": "Unveil the Ancient Wonders of Athens",
        "trip_duration": "4 days 3 nights",
        "price": 1100,
        "group_size": "2 people",
        "route": "Athens",
        "description": "Athens, the historic capital of Greece, is known for its ancient monuments, vibrant culture, and delicious cuisine. This itinerary captures the essence of Athens, from its iconic landmarks to its bustling neighborhoods.",
        "available_months": "March - June, September - November",
        "itinerary": [
            {
                "city": "Athens",
                "days": "Day 1",
                "description": "Begin your Athens adventure with a visit to the Acropolis, the most famous ancient site in Greece. Explore the Parthenon, the Erechtheion, and the Temple of Athena Nike. In the afternoon, visit the Acropolis Museum to see artifacts from the Acropolis site. End your day with a leisurely stroll through the Plaka neighborhood, known for its charming streets and traditional tavernas.",
                "highlights": [
                    "Acropolis: Iconic ancient site with historic temples",
                    "Parthenon: Ancient temple dedicated to Athena",
                    "Acropolis Museum: Museum with artifacts from the Acropolis",
                    "Plaka: Charming neighborhood with traditional tavernas"
                ],
                "coordinates": {
                    "lat": 37.9715,
                    "lng": 23.7257
                }
            },
            {
                "city": "Athens",
                "days": "Day 2",
                "description": "Visit the Ancient Agora, the heart of ancient Athens, and explore the ruins of the Temple of Hephaestus and the Stoa of Attalos. In the afternoon, head to the National Archaeological Museum, home to a vast collection of Greek antiquities. Spend the evening in the Monastiraki neighborhood, famous for its flea market and vibrant atmosphere.",
                "highlights": [
                    "Ancient Agora: Central public space in ancient Athens",
                    "Temple of Hephaestus: Well-preserved ancient temple",
                    "National Archaeological Museum: Extensive collection of Greek antiquities",
                    "Monastiraki: Neighborhood with flea market and vibrant atmosphere"
                ],
                "coordinates": {
                    "lat": 37.9755,
                    "lng": 23.7213
                }
            },
            {
                "city": "Athens",
                "days": "Day 3",
                "description": "Take a day trip to Cape Sounion to visit the Temple of Poseidon, offering breathtaking views of the Aegean Sea. In the afternoon, return to Athens and explore the Panathenaic Stadium, the site of the first modern Olympic Games. End your day with a visit to the Lycabettus Hill for a panoramic view of the city, followed by dinner at a rooftop restaurant.",
                "highlights": [
                    "Cape Sounion: Coastal site with the Temple of Poseidon",
                    "Temple of Poseidon: Ancient temple with sea views",
                    "Panathenaic Stadium: Historic stadium and site of the first modern Olympics",
                    "Lycabettus Hill: Hill with panoramic city views",
                    "Rooftop Restaurant: Dinner with views of Athens"
                ],
                "coordinates": {
                    "lat": 37.8407,
                    "lng": 24.0204
                }
            },
            {
                "city": "Athens",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Benaki Museum, showcasing Greek art and culture from antiquity to modern times. In the afternoon, explore the upscale Kolonaki neighborhood, known for its boutiques and cafes. Finish your Athens adventure with a relaxing walk through the National Garden and a visit to the nearby Zappeion Hall.",
                "highlights": [
                    "Benaki Museum: Museum of Greek art and culture",
                    "Kolonaki: Upscale neighborhood with boutiques and cafes",
                    "National Garden: Public park in the center of Athens",
                    "Zappeion Hall: Historic building and event space"
                ],
                "coordinates": {
                    "lat": 37.9751,
                    "lng": 23.7341
                }
            }
        ]
    },
    
    {
        "city": "Los Angeles",
        "country": "USA",
        "image_desc": "Hollywood Sign",
        "image_url": "https://images.pexels.com/photos/277344/pexels-photo-277344.jpeg",
        "itinerary_title": "Explore the Glamour and Culture of Los Angeles",
        "trip_duration": "5 days 4 nights",
        "price": 1400,
        "group_size": "2 people",
        "route": "Los Angeles",
        "description": "Los Angeles, known as the entertainment capital of the world, offers a mix of glamorous attractions, cultural experiences, and beautiful beaches. This itinerary captures the essence of LA, from Hollywood to the Pacific Coast.",
        "available_months": "March - June, September - November",
        "itinerary": [
            {
                "city": "Los Angeles",
                "days": "Day 1",
                "description": "Start your LA adventure with a visit to the Hollywood Walk of Fame. Explore the TCL Chinese Theatre and take a tour of the Dolby Theatre, home of the Oscars. In the afternoon, hike up to the Griffith Observatory for stunning views of the city and the iconic Hollywood Sign.",
                "highlights": [
                    "Hollywood Walk of Fame: Famous sidewalk with celebrity stars",
                    "TCL Chinese Theatre: Historic theatre with celebrity handprints",
                    "Dolby Theatre: Venue for the Oscars",
                    "Griffith Observatory: Observatory with views of LA and the Hollywood Sign"
                ],
                "coordinates": {
                    "lat": 34.1016,
                    "lng": -118.3269
                }
            },
            {
                "city": "Los Angeles",
                "days": "Day 2",
                "description": "Spend the day at Universal Studios Hollywood, enjoying thrilling rides and shows. Explore the behind-the-scenes Studio Tour for a glimpse into the movie-making process. In the evening, visit the Universal CityWalk for dining, shopping, and entertainment.",
                "highlights": [
                    "Universal Studios Hollywood: Theme park with rides and shows",
                    "Studio Tour: Behind-the-scenes look at movie production",
                    "Universal CityWalk: Dining, shopping, and entertainment complex"
                ],
                "coordinates": {
                    "lat": 34.1381,
                    "lng": -118.3534
                }
            },
            {
                "city": "Los Angeles",
                "days": "Day 3",
                "description": "Discover the cultural side of LA with a visit to the Getty Center, featuring impressive art collections and stunning architecture. In the afternoon, head to the Los Angeles County Museum of Art (LACMA) and see the iconic Urban Light installation. End your day with a stroll through The Grove and the Original Farmers Market.",
                "highlights": [
                    "Getty Center: Art museum with beautiful gardens and architecture",
                    "LACMA: Art museum with extensive collections",
                    "Urban Light: Iconic art installation at LACMA",
                    "The Grove: Shopping and dining destination",
                    "Original Farmers Market: Historic market with diverse food options"
                ],
                "coordinates": {
                    "lat": 34.0775,
                    "lng": -118.4741
                }
            },
            {
                "city": "Los Angeles",
                "days": "Day 4",
                "description": "Take a scenic drive along the Pacific Coast Highway to Santa Monica. Enjoy the sun and surf at Santa Monica Beach and visit the historic Santa Monica Pier. In the afternoon, explore the trendy Venice Beach Boardwalk and its unique shops and street performers. End your day with dinner at a beachfront restaurant.",
                "highlights": [
                    "Pacific Coast Highway: Scenic coastal drive",
                    "Santa Monica Beach: Iconic beach with a historic pier",
                    "Santa Monica Pier: Amusement park and entertainment area",
                    "Venice Beach Boardwalk: Trendy area with shops and performers",
                    "Beachfront Restaurant: Dining with ocean views"
                ],
                "coordinates": {
                    "lat": 34.0195,
                    "lng": -118.4912
                }
            },
            {
                "city": "Los Angeles",
                "days": "Day 5",
                "description": "Conclude your trip with a visit to downtown LA. Explore the historic Olvera Street, the birthplace of Los Angeles. Visit the Walt Disney Concert Hall, known for its striking architecture. In the afternoon, relax at Grand Park and enjoy views of City Hall. Finish your LA adventure with a dinner in the vibrant Arts District.",
                "highlights": [
                    "Olvera Street: Historic street with Mexican heritage",
                    "Walt Disney Concert Hall: Iconic concert hall with unique design",
                    "Grand Park: Urban park with views of City Hall",
                    "Arts District: Vibrant area with galleries and dining"
                ],
                "coordinates": {
                    "lat": 34.0553,
                    "lng": -118.2468
                }
            }
        ]
    },
    
    {
        "city": "Hong Kong",
        "country": "China",
        "image_desc": "Victoria Peak",
        "image_url": "https://images.pexels.com/photos/236820/pexels-photo-236820.jpeg",
        "itinerary_title": "Discover the Dynamic City of Hong Kong",
        "trip_duration": "4 days 3 nights",
        "price": 1300,
        "group_size": "2 people",
        "route": "Hong Kong",
        "description": "Hong Kong, a vibrant metropolis known for its skyscrapers, bustling markets, and rich cultural heritage, offers a unique blend of East and West. This itinerary captures the essence of Hong Kong, from its iconic skyline to its historic temples.",
        "available_months": "October - April",
        "itinerary": [
            {
                "city": "Hong Kong",
                "days": "Day 1",
                "description": "Start your Hong Kong adventure with a visit to Victoria Peak. Take the Peak Tram to the top and enjoy panoramic views of the city skyline and Victoria Harbour. In the afternoon, explore the bustling Central district and visit the Man Mo Temple, one of the oldest temples in Hong Kong. End your day with a stroll through the vibrant Soho area, known for its dining and nightlife.",
                "highlights": [
                    "Victoria Peak: Panoramic views of Hong Kong",
                    "Peak Tram: Historic funicular railway to the Peak",
                    "Central District: Bustling business district",
                    "Man Mo Temple: Historic temple dedicated to the gods of literature and martial arts",
                    "Soho: Vibrant area with dining and nightlife"
                ],
                "coordinates": {
                    "lat": 22.2758,
                    "lng": 114.1455
                }
            },
            {
                "city": "Hong Kong",
                "days": "Day 2",
                "description": "Visit the Ngong Ping 360, a cable car ride offering stunning views of Lantau Island and the South China Sea. Explore the Po Lin Monastery and the Tian Tan Buddha, a massive bronze statue. In the afternoon, visit the Tai O Fishing Village, known for its stilt houses and traditional way of life.",
                "highlights": [
                    "Ngong Ping 360: Cable car ride with scenic views",
                    "Po Lin Monastery: Historic monastery on Lantau Island",
                    "Tian Tan Buddha: Massive bronze Buddha statue",
                    "Tai O Fishing Village: Traditional village with stilt houses"
                ],
                "coordinates": {
                    "lat": 22.2530,
                    "lng": 113.9041
                }
            },
            {
                "city": "Hong Kong",
                "days": "Day 3",
                "description": "Spend the day exploring Kowloon. Visit the Wong Tai Sin Temple, famous for the practice of Kau Cim (fortune telling). In the afternoon, explore the bustling markets of Mong Kok, including the Ladies' Market and the Goldfish Market. End your day with a visit to the Tsim Sha Tsui Promenade, and enjoy the Symphony of Lights show, a nightly multimedia display.",
                "highlights": [
                    "Wong Tai Sin Temple: Temple known for fortune telling",
                    "Mong Kok: Bustling area with various markets",
                    "Ladies' Market: Popular market with fashion and accessories",
                    "Goldfish Market: Market specializing in aquarium fish",
                    "Tsim Sha Tsui Promenade: Scenic walkway with harbor views",
                    "Symphony of Lights: Nightly multimedia light show"
                ],
                "coordinates": {
                    "lat": 22.3166,
                    "lng": 114.1803
                }
            },
            {
                "city": "Hong Kong",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Hong Kong Museum of History to learn about the city's rich heritage. In the afternoon, take a ferry ride to the outlying island of Cheung Chau. Explore the island's quaint streets, enjoy the beaches, and sample fresh seafood. Return to Hong Kong Island in the evening and enjoy a farewell dinner at a rooftop restaurant with views of the city skyline.",
                "highlights": [
                    "Hong Kong Museum of History: Museum showcasing Hong Kong's heritage",
                    "Cheung Chau Island: Outlying island with quaint streets and beaches",
                    "Ferry Ride: Scenic boat ride to and from the island",
                    "Rooftop Restaurant: Dinner with views of the city skyline"
                ],
                "coordinates": {
                    "lat": 22.2849,
                    "lng": 114.1589
                }
            }
        ]
    },

    {
        "city": "Vienna",
        "country": "Austria",
        "image_desc": "Schönbrunn Palace",
        "image_url": "https://images.pexels.com/photos/347734/pexels-photo-347734.jpeg",
        "itinerary_title": "Discover the Imperial Charm of Vienna",
        "trip_duration": "4 days 3 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Vienna",
        "description": "Vienna, the capital of Austria, is known for its imperial history, grand architecture, and vibrant cultural scene. This itinerary captures the essence of Vienna, from its historic palaces to its bustling coffeehouses.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Vienna",
                "days": "Day 1",
                "description": "Begin your Vienna adventure with a visit to Schönbrunn Palace, the former summer residence of the Habsburgs. Explore the palace's opulent rooms and beautiful gardens. In the afternoon, visit the Belvedere Palace, known for its stunning baroque architecture and impressive art collection, including works by Gustav Klimt.",
                "highlights": [
                    "Schönbrunn Palace: Opulent former summer residence of the Habsburgs",
                    "Belvedere Palace: Baroque palace with impressive art collections",
                    "Gustav Klimt: Renowned artist with works displayed at Belvedere"
                ],
                "coordinates": {
                    "lat": 48.1845,
                    "lng": 16.3122
                }
            },
            {
                "city": "Vienna",
                "days": "Day 2",
                "description": "Visit the Hofburg Palace, the former imperial winter residence. Explore the Sisi Museum, the Imperial Apartments, and the Silver Collection. In the afternoon, stroll through the historic center and visit St. Stephen's Cathedral, one of Vienna's most iconic landmarks.",
                "highlights": [
                    "Hofburg Palace: Former imperial winter residence",
                    "Sisi Museum: Museum dedicated to Empress Elisabeth",
                    "St. Stephen's Cathedral: Iconic Gothic cathedral in the heart of Vienna"
                ],
                "coordinates": {
                    "lat": 48.2065,
                    "lng": 16.3655
                }
            },
            {
                "city": "Vienna",
                "days": "Day 3",
                "description": "Spend the day at the MuseumsQuartier, one of the largest cultural complexes in the world. Visit the Leopold Museum, the Museum of Modern Art (MUMOK), and the Kunsthistorisches Museum. In the evening, enjoy a classical music concert at the Vienna State Opera or the Musikverein.",
                "highlights": [
                    "MuseumsQuartier: Large cultural complex with various museums",
                    "Leopold Museum: Museum with Austrian art, including works by Egon Schiele",
                    "Museum of Modern Art (MUMOK): Modern and contemporary art museum",
                    "Vienna State Opera: Renowned opera house",
                    "Musikverein: Famous concert hall known for its acoustics"
                ],
                "coordinates": {
                    "lat": 48.2036,
                    "lng": 16.3582
                }
            },
            {
                "city": "Vienna",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Prater, a large public park home to the famous Giant Ferris Wheel. In the afternoon, explore the Naschmarkt, Vienna's most popular market, offering a variety of fresh produce, spices, and international foods. Finish your Vienna adventure with coffee and cake at one of the city's historic coffeehouses.",
                "highlights": [
                    "Prater: Large public park with the Giant Ferris Wheel",
                    "Naschmarkt: Popular market with fresh produce and international foods",
                    "Historic Coffeehouses: Experience traditional Viennese coffee culture"
                ],
                "coordinates": {
                    "lat": 48.2160,
                    "lng": 16.4003
                }
            }
        ]
    },
    
    {
        "city": "San Francisco",
        "country": "USA",
        "image_desc": "Golden Gate Bridge",
        "image_url": "https://images.pexels.com/photos/733174/pexels-photo-733174.jpeg",
        "itinerary_title": "Explore the Charm of San Francisco",
        "trip_duration": "4 days 3 nights",
        "price": 1350,
        "group_size": "2 people",
        "route": "San Francisco",
        "description": "San Francisco, known for its iconic landmarks, diverse neighborhoods, and stunning bay views, offers a rich blend of culture and history. This itinerary captures the best of San Francisco, from the Golden Gate Bridge to vibrant districts like Chinatown and the Mission.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "San Francisco",
                "days": "Day 1",
                "description": "Start your San Francisco adventure with a visit to the iconic Golden Gate Bridge. Walk or bike across the bridge for breathtaking views. In the afternoon, explore the Presidio and visit Crissy Field. End your day at Fisherman's Wharf, where you can enjoy fresh seafood and visit Pier 39 to see the sea lions.",
                "highlights": [
                    "Golden Gate Bridge: Iconic suspension bridge with scenic views",
                    "Presidio: Historic park with trails and museums",
                    "Crissy Field: Recreational area with beach and picnic spots",
                    "Fisherman's Wharf: Lively waterfront area with seafood and attractions",
                    "Pier 39: Popular pier with sea lions and entertainment"
                ],
                "coordinates": {
                    "lat": 37.8199,
                    "lng": -122.4783
                }
            },
            {
                "city": "San Francisco",
                "days": "Day 2",
                "description": "Visit Alcatraz Island and tour the infamous former prison. In the afternoon, explore Chinatown, one of the oldest and largest in North America. Stroll through Grant Avenue, visit traditional shops, and enjoy authentic Chinese cuisine. In the evening, head to North Beach, San Francisco's Little Italy, for dinner and nightlife.",
                "highlights": [
                    "Alcatraz Island: Historic island with former prison tours",
                    "Chinatown: Historic district with shops and restaurants",
                    "Grant Avenue: Main street in Chinatown with traditional shops",
                    "North Beach: Vibrant neighborhood known as Little Italy"
                ],
                "coordinates": {
                    "lat": 37.8270,
                    "lng": -122.4230
                }
            },
            {
                "city": "San Francisco",
                "days": "Day 3",
                "description": "Explore Golden Gate Park, home to various attractions including the California Academy of Sciences, de Young Museum, and Japanese Tea Garden. In the afternoon, visit the Haight-Ashbury neighborhood, known for its colorful history and eclectic shops. End your day with a walk down Lombard Street, the 'crookedest street in the world.'",
                "highlights": [
                    "Golden Gate Park: Large park with museums and gardens",
                    "California Academy of Sciences: Museum with aquarium and planetarium",
                    "de Young Museum: Fine arts museum in Golden Gate Park",
                    "Japanese Tea Garden: Tranquil garden in Golden Gate Park",
                    "Haight-Ashbury: Historic neighborhood with eclectic shops",
                    "Lombard Street: Famous crooked street with scenic views"
                ],
                "coordinates": {
                    "lat": 37.7694,
                    "lng": -122.4862
                }
            },
            {
                "city": "San Francisco",
                "days": "Day 4",
                "description": "Take a ride on one of San Francisco's historic cable cars. Visit the Mission District and explore its vibrant street art, including the famous murals on Clarion Alley. Enjoy a meal at a trendy restaurant in the area. In the afternoon, head to the Embarcadero and visit the Ferry Building Marketplace. Conclude your trip with a sunset cruise on San Francisco Bay.",
                "highlights": [
                    "Cable Cars: Historic streetcars with scenic city routes",
                    "Mission District: Vibrant neighborhood with street art and murals",
                    "Clarion Alley: Famous alley with colorful murals",
                    "Embarcadero: Waterfront area with shops and attractions",
                    "Ferry Building Marketplace: Market with local food and goods",
                    "Sunset Cruise: Evening boat ride with views of the bay"
                ],
                "coordinates": {
                    "lat": 37.7749,
                    "lng": -122.4194
                }
            }
        ]
    },
    
    {
        "city": "Lisbon",
        "country": "Portugal",
        "image_desc": "Belém Tower",
        "image_url": "https://images.pexels.com/photos/279523/pexels-photo-279523.jpeg",
        "itinerary_title": "Experience the Charm of Lisbon",
        "trip_duration": "4 days 3 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Lisbon",
        "description": "Lisbon, the capital of Portugal, is known for its stunning architecture, historic neighborhoods, and vibrant culture. This itinerary captures the essence of Lisbon, from its historic sites to its modern attractions.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Lisbon",
                "days": "Day 1",
                "description": "Start your Lisbon adventure with a visit to the historic Belém district. Explore the Jerónimos Monastery, a UNESCO World Heritage site, and the nearby Belém Tower. In the afternoon, visit the Monument to the Discoveries and enjoy a pastel de nata at the famous Pastéis de Belém bakery. End your day with a relaxing walk along the Tagus River.",
                "highlights": [
                    "Jerónimos Monastery: UNESCO World Heritage site",
                    "Belém Tower: Historic fortress on the Tagus River",
                    "Monument to the Discoveries: Tribute to Portuguese explorers",
                    "Pastéis de Belém: Famous bakery known for its pastel de nata",
                    "Tagus River: Scenic waterfront area"
                ],
                "coordinates": {
                    "lat": 38.6972,
                    "lng": -9.2064
                }
            },
            {
                "city": "Lisbon",
                "days": "Day 2",
                "description": "Explore the Alfama district, the oldest neighborhood in Lisbon. Visit the São Jorge Castle for panoramic views of the city and explore the narrow, winding streets of Alfama. In the afternoon, visit the Lisbon Cathedral and the National Pantheon. Enjoy a fado music performance in the evening.",
                "highlights": [
                    "Alfama: Oldest neighborhood with narrow, winding streets",
                    "São Jorge Castle: Historic castle with panoramic views",
                    "Lisbon Cathedral: Historic cathedral in the heart of Alfama",
                    "National Pantheon: Monumental building with historic significance",
                    "Fado Music: Traditional Portuguese music performance"
                ],
                "coordinates": {
                    "lat": 38.7139,
                    "lng": -9.1334
                }
            },
            {
                "city": "Lisbon",
                "days": "Day 3",
                "description": "Spend the day exploring the Bairro Alto and Chiado districts. Visit the Church of São Roque and the Carmo Convent. In the afternoon, take the iconic Tram 28 for a scenic ride through the city's historic neighborhoods. End your day with dinner at a rooftop restaurant in Chiado.",
                "highlights": [
                    "Bairro Alto: Lively neighborhood with shops and nightlife",
                    "Chiado: Historic area with theaters and cafes",
                    "Church of São Roque: Historic church with beautiful interior",
                    "Carmo Convent: Ruins of a Gothic church",
                    "Tram 28: Scenic tram ride through historic neighborhoods",
                    "Rooftop Restaurant: Dinner with views of Lisbon"
                ],
                "coordinates": {
                    "lat": 38.7110,
                    "lng": -9.1426
                }
            },
            {
                "city": "Lisbon",
                "days": "Day 4",
                "description": "Visit the modern Parque das Nações district, home to the Oceanário de Lisboa, one of the largest aquariums in the world. Explore the nearby Vasco da Gama Tower and enjoy a walk along the waterfront. In the afternoon, visit the Calouste Gulbenkian Museum, which houses an extensive collection of art. End your trip with a sunset cruise on the Tagus River.",
                "highlights": [
                    "Parque das Nações: Modern district with attractions",
                    "Oceanário de Lisboa: One of the largest aquariums in the world",
                    "Vasco da Gama Tower: Tallest building in Lisbon with observation deck",
                    "Calouste Gulbenkian Museum: Art museum with diverse collections",
                    "Sunset Cruise: Evening boat ride on the Tagus River"
                ],
                "coordinates": {
                    "lat": 38.7689,
                    "lng": -9.0940
                }
            }
        ]
    },
    
    {
        "city": "Venice",
        "country": "Italy",
        "image_desc": "Grand Canal",
        "image_url": "https://images.pexels.com/photos/208733/pexels-photo-208733.jpeg",
        "itinerary_title": "Experience the Magic of Venice",
        "trip_duration": "4 days 3 nights",
        "price": 1400,
        "group_size": "2 people",
        "route": "Venice",
        "description": "Venice, known for its canals, historic architecture, and rich cultural heritage, offers a unique and romantic travel experience. This itinerary captures the essence of Venice, from its famous landmarks to its hidden gems.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Venice",
                "days": "Day 1",
                "description": "Begin your Venice adventure with a visit to St. Mark's Square. Explore St. Mark's Basilica and the Campanile for stunning views of the city. In the afternoon, visit the Doge's Palace and walk across the Bridge of Sighs. End your day with a gondola ride through the canals.",
                "highlights": [
                    "St. Mark's Square: Iconic public square with historic buildings",
                    "St. Mark's Basilica: Famous cathedral with stunning mosaics",
                    "Campanile: Bell tower offering panoramic views",
                    "Doge's Palace: Historic palace and museum",
                    "Bridge of Sighs: Historic bridge with a unique story",
                    "Gondola Ride: Traditional boat ride through the canals"
                ],
                "coordinates": {
                    "lat": 45.4345,
                    "lng": 12.3397
                }
            },
            {
                "city": "Venice",
                "days": "Day 2",
                "description": "Visit the Rialto Market in the morning and explore the Rialto Bridge. In the afternoon, visit the Peggy Guggenheim Collection, a modern art museum located in the Palazzo Venier dei Leoni. Enjoy lunch at a nearby trattoria and spend the evening exploring the Dorsoduro district.",
                "highlights": [
                    "Rialto Market: Vibrant market with fresh produce and seafood",
                    "Rialto Bridge: Historic and iconic bridge",
                    "Peggy Guggenheim Collection: Modern art museum",
                    "Palazzo Venier dei Leoni: Historic palace housing the museum",
                    "Dorsoduro: Charming district with art galleries and cafes"
                ],
                "coordinates": {
                    "lat": 45.4379,
                    "lng": 12.3356
                }
            },
            {
                "city": "Venice",
                "days": "Day 3",
                "description": "Take a day trip to the islands of Murano and Burano. Visit Murano to see the famous glass-blowing workshops and museums. In the afternoon, head to Burano, known for its colorful houses and lace-making tradition. Return to Venice in the evening and enjoy a dinner at a canal-side restaurant.",
                "highlights": [
                    "Murano: Island famous for glass-blowing",
                    "Glass-Blowing Workshops: See artisans at work",
                    "Burano: Island known for colorful houses and lace-making",
                    "Canal-Side Restaurant: Dinner with views of the canals"
                ],
                "coordinates": {
                    "lat": 45.4573,
                    "lng": 12.3530
                }
            },
            {
                "city": "Venice",
                "days": "Day 4",
                "description": "Conclude your trip with a visit to the Gallerie dell'Accademia, home to an impressive collection of Venetian art. In the afternoon, explore the historic Jewish Ghetto and learn about its history at the Jewish Museum. End your Venice adventure with a leisurely walk along the Zattere promenade, enjoying the views of the Giudecca Canal.",
                "highlights": [
                    "Gallerie dell'Accademia: Museum with Venetian art",
                    "Jewish Ghetto: Historic area with a rich history",
                    "Jewish Museum: Learn about the history of the Jewish Ghetto",
                    "Zattere Promenade: Scenic walk along the Giudecca Canal"
                ],
                "coordinates": {
                    "lat": 45.4340,
                    "lng": 12.3265
                }
            }
        ]
    },
    
    
    {
        "city": "Paris",
        "country": "France",
        "image_desc": "Eiffel Tower",
        "image_url": "https://images.pexels.com/photos/338515/pexels-photo-338515.jpeg",
        "itinerary_title": "Discover the Romance and Charm of Paris",
        "trip_duration": "5 days 4 nights",
        "price": 1200,
        "group_size": "2 people",
        "route": "Paris",
        "description": "Paris, the City of Light, is known for its stunning architecture, rich history, and vibrant culture. From the iconic Eiffel Tower to the artistic Montmartre district, Paris offers a wealth of experiences. Stroll along the Seine, explore world-class museums, and indulge in exquisite French cuisine. This itinerary captures the essence of Paris, blending must-see landmarks with hidden gems.",
        "available_months": "April - October",
        "itinerary": [
            {
                "city": "Paris",
                "days": "Day 1",
                "description": "Begin your Parisian adventure with a visit to the iconic Eiffel Tower. Enjoy the panoramic views from the top, then take a leisurely cruise along the Seine River to see the city's landmarks from a unique perspective.",
                "highlights": [
                    "Eiffel Tower: Iconic landmark with panoramic views",
                    "Seine River Cruise: Scenic views of Paris' landmarks",
                    "Champs-Élysées: Famous avenue for shopping and dining"
                ],
                "coordinates": {
                    "lat": 48.8584,
                    "lng": 2.2945
                }
            },
            {
                "city": "Paris",
                "days": "Day 2",
                "description": "Explore the historic heart of Paris with a visit to Notre-Dame Cathedral and the Louvre Museum. Discover the artistic treasures within the Louvre, including the Mona Lisa and Venus de Milo.",
                "highlights": [
                    "Notre-Dame Cathedral: Gothic architectural masterpiece",
                    "Louvre Museum: World-renowned art collections",
                    "Tuileries Garden: Beautiful public garden near the Louvre"
                ],
                "coordinates": {
                    "lat": 48.8530,
                    "lng": 2.3499
                }
            },
            {
                "city": "Paris",
                "days": "Day 3",
                "description": "Venture to Montmartre, the artistic district of Paris. Visit the stunning Sacré-Cœur Basilica, stroll through charming streets, and enjoy the vibrant atmosphere of Place du Tertre.",
                "highlights": [
                    "Sacré-Cœur Basilica: Stunning basilica with city views",
                    "Place du Tertre: Lively square with artists and cafes",
                    "Montmartre: Historic and artistic neighborhood"
                ],
                "coordinates": {
                    "lat": 48.8867,
                    "lng": 2.3431
                }
            },
            {
                "city": "Paris",
                "days": "Day 4",
                "description": "Immerse yourself in the luxurious side of Paris with visits to the Palace of Versailles and the Opéra Garnier. Experience the grandeur of French royalty and the elegance of Parisian opera.",
                "highlights": [
                    "Palace of Versailles: Extravagant palace and gardens",
                    "Opéra Garnier: Historic opera house with opulent decor",
                    "Galeries Lafayette: High-end department store"
                ],
                "coordinates": {
                    "lat": 48.8049,
                    "lng": 2.1204
                }
            },
            {
                "city": "Paris",
                "days": "Day 5",
                "description": "Conclude your trip with a leisurely day exploring the Marais district. Discover its trendy boutiques, historic mansions, and vibrant cultural scene.",
                "highlights": [
                    "Marais District: Trendy area with boutiques and cafes",
                    "Place des Vosges: Historic square with beautiful architecture",
                    "Carnavalet Museum: Museum of Parisian history"
                ],
                "coordinates": {
                    "lat": 48.8586,
                    "lng": 2.3622
                }
            }
        ]
    },
    

    {
        "city": "Tokyo",
        "country": "Japan",
        "image_desc": "Tokyo Tower",
        "image_url": "https://images.pexels.com/photos/2614818/pexels-photo-2614818.jpeg",
        "itinerary_title": "Explore the Dynamic and Modern City of Tokyo",
        "trip_duration": "5 days 4 nights",
        "price": 1500,
        "group_size": "2 people",
        "route": "Tokyo",
        "description": "Tokyo, the bustling capital of Japan, is a city where tradition meets modernity. From towering skyscrapers and cutting-edge technology to historic temples and serene gardens, Tokyo offers a diverse range of experiences. This itinerary showcases the best of Tokyo, blending iconic landmarks, cultural experiences, and culinary delights.",
        "available_months": "April - November",
        "itinerary": [
            {
                "city": "Tokyo",
                "days": "Day 1",
                "description": "Begin your Tokyo adventure with a visit to the iconic Tokyo Tower. Explore the nearby Zojo-ji Temple and enjoy panoramic views of the city. In the evening, wander through the vibrant streets of Shibuya and witness the famous Shibuya Crossing.",
                "highlights": [
                    "Tokyo Tower: Iconic landmark with observation decks",
                    "Zojo-ji Temple: Historic Buddhist temple",
                    "Shibuya Crossing: Famous pedestrian scramble"
                ],
                "coordinates": {
                    "lat": 35.6586,
                    "lng": 139.7454
                }
            },
            {
                "city": "Tokyo",
                "days": "Day 2",
                "description": "Discover the traditional side of Tokyo with a visit to the historic Asakusa district. Explore the famous Senso-ji Temple and stroll through Nakamise Street. In the afternoon, take a boat cruise on the Sumida River.",
                "highlights": [
                    "Senso-ji Temple: Tokyo's oldest temple",
                    "Nakamise Street: Shopping street with traditional goods",
                    "Sumida River Cruise: Scenic boat ride"
                ],
                "coordinates": {
                    "lat": 35.7148,
                    "lng": 139.7967
                }
            },
            {
                "city": "Tokyo",
                "days": "Day 3",
                "description": "Visit the bustling district of Shinjuku, starting with the observation deck at the Tokyo Metropolitan Government Building. Explore Shinjuku Gyoen National Garden and experience the nightlife in Kabukicho.",
                "highlights": [
                    "Tokyo Metropolitan Government Building: Observation deck with city views",
                    "Shinjuku Gyoen: Large park with traditional Japanese gardens",
                    "Kabukicho: Lively nightlife district"
                ],
                "coordinates": {
                    "lat": 35.6895,
                    "lng": 139.6917
                }
            },
            {
                "city": "Tokyo",
                "days": "Day 4",
                "description": "Immerse yourself in Tokyo's pop culture with a visit to the Akihabara district, known for its electronics and anime shops. In the afternoon, explore Ueno Park and its many museums, including the Tokyo National Museum.",
                "highlights": [
                    "Akihabara: Hub of electronics and anime culture",
                    "Ueno Park: Large public park with museums and a zoo",
                    "Tokyo National Museum: Extensive collection of art and artifacts"
                ],
                "coordinates": {
                    "lat": 35.7101,
                    "lng": 139.8107
                }
            },
            {
                "city": "Tokyo",
                "days": "Day 5",
                "description": "End your Tokyo trip with a visit to the trendy Harajuku district. Explore the Meiji Shrine and the fashionable Takeshita Street. In the evening, relax and enjoy the views from the Roppongi Hills observation deck.",
                "highlights": [
                    "Meiji Shrine: Shinto shrine surrounded by forest",
                    "Takeshita Street: Trendy shopping street",
                    "Roppongi Hills: Modern complex with observation deck"
                ],
                "coordinates": {
                    "lat": 35.6705,
                    "lng": 139.7022
                }
            }
        ]
    },
    
 
    








];

