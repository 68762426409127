export const isWorkingUrl = async (url)  => {
    if (!url) {
        return false;
    }

    try {
      const response = await fetch(url, { method: 'GET' });
      //console.log(url, response);
      return response.ok;
    } catch (error) {
        console.error('Error checking URL:', error);
      return false;
    }

}


export const isEmpty = (value) => {
  if (value === null || value === undefined) {
      return true;
  }
  
  if (typeof value === 'string') {
      return value.trim().length === 0;
  }
  
  if (Array.isArray(value)) {
      return value.length === 0;
  }
  
  if (typeof value === 'object') {
      return Object.keys(value).length === 0 && value.constructor === Object;
  }
  
  return false; // For other types, return false if they are not empty by default
}

export const decodeHTMLEntities = (str) => {
    if (!str) {
        // If str is undefined, null, or an empty string, return an empty string
        return '';
    }

    const entityMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&#x2F;': '/',
        '&#x60;': '`',
        '&#x3D;': '='
    };

    return str.replace(/&(?:#(\d+)|#x([0-9a-fA-F]+)|[a-zA-Z0-9]+);/g, function(match, dec, hex) {
        if (dec) {
            // Decimal entity
            return String.fromCharCode(dec);
        } else if (hex) {
            // Hexadecimal entity
            return String.fromCharCode(parseInt(hex, 16));
        }
        // Named entity or unknown entity
        return entityMap[match] || match;
    });
};

export const stripHtmlTags = (htmlString) => {
    // Create a temporary DOM element
    let tempDiv = document.createElement("div");
    
    // Assign the HTML string to the innerHTML property
    tempDiv.innerHTML = htmlString;
    
    // Get the text content, which strips the HTML tags
    return tempDiv.textContent || tempDiv.innerText || "";
}