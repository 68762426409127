<template>
    <div class="itinerary-container">
      <HeaderLevel2
        title="Popular Itinerary" 
        :iconSrc="itineraryIcon" 
        altText="Popular Itinerary"
      />
      <CarouselSlide v-if="!isLoading">
        <ItineraryCard v-for="itinerary in shownRandom" :key="itinerary.id" :itinerary="itinerary"/>
      </CarouselSlide>
      <div class="loading-indicator" v-else>
        <LoadingSpinner />
      </div>
    </div>
</template>

<script>
import itineraryIcon from '@/assets/icons/itinerary_icon.png';
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import CarouselSlide from '@/components/ui/util/CarouselSlide.vue';
import ItineraryCard from './ItineraryCard.vue';
import { getItineraries } from '@/components/services/itineraryService.js';
import LoadingSpinner from '@/components/ui/chatPage/LoadingSpinner.vue';

export default {
  name: 'PopularItinerary',
  components: {
    HeaderLevel2,
    CarouselSlide,
    ItineraryCard,
    LoadingSpinner,
  },
  data() {
    return {
        itineraries: [],
        itineraryIcon,
        shownRandom: [],
        isLoading: true,
    };
  },
  mounted() {
    // console.log('Parent component name:', this.$parent?.$options.name);
    // console.log('Parent component instance:', this.$parent);
    this.fetchItineraries();
    //console.log(this.shownRandom);
  },
  methods: {
    async fetchItineraries() {
      this.isLoading = true;
      try {
        this.itineraries = await getItineraries();
        this.shownRandom = this.getRandom(this.itineraries);
      } catch (error) {
        console.error('Error fetching itineraries:', error);    
      } finally {
        this.isLoading = false;
      }
    },
    getRandom(data) {
      const maxNum = 12;
      let shuffled = data.sort(() => 0.5 - Math.random());
      // console.log(shuffled.slice(0, numOfCitiesShown));
      return shuffled.slice(0, maxNum);
    },
  },
};
</script>

<style scoped>
.itinerary-container {
  margin: 20px 5px;
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1em;
}

</style>