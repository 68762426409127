<template>
    <button @click="handleClick" class="order-button">
      {{ buttonText }}<br>
      <img src="@/assets/itineraries/powerByTripToGo.png" width='150' alt="Powered by TripToGo" />
    </button>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'orderButton',
    props: {
      buttonText: {
        type: String,
        default: 'Explore',
      },
    },
    emits: ['click'],
    setup(_, { emit }) {
      const handleClick = () => {
        emit('click');
      };
  
      return {
        handleClick,
      };
    },
  });
  </script>
  
  <style scoped>
  .order-button {
    background-color: var(--primary-color);
    color: var(--white);
    border: 2px solid var(--primary-color);
    border-radius: 12px;
    padding: 14px 30px;
    font-size: 1.43em;
    font-weight: bold;
    /* text-transform: uppercase; */
    cursor: pointer;
  }
  
  img {
    width: 150;
    margin: 8px 0;
  }

  .order-button:hover {
    background-color: var(--secondary-color);
    color: var(--white);
    border: 2px solid var(--primary-color);
  }
  </style>
  