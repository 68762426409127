<template>
  <div class="header-container">
    <div class="header-background">
      <img :src="itinerary.image_url" :alt="itinerary.name" @error="handleImageError" class="hero-image" />
    </div>
    <div class="header-content">
      <ClosePopupButton />
      <GoHomeButton />
      <div class="header-title">
        <img src="@/assets/icons/itinerary_icon.png" alt="itinerary" class="title-icon">
        <span>Itinerary</span>
      </div>
      <div class="icon-container">
        <HeroButton icon="publish_icon.png" altText="publish" link="" />
        <HeroButton icon="favourite.png" altText="favourite" link="" />
        <HeroButton icon="share.png" altText="share" link="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, computed } from 'vue';
import HeroButton from '@/components/ui/homePage/HeroButton.vue';
import ClosePopupButton from '@/components/ui/util/ClosePopupButton.vue';
import GoHomeButton from '@/components/ui/util/GoHomeButton.vue';

export default {
  name: 'DestinationHeader',
  components: {
    HeroButton,
    ClosePopupButton,
    GoHomeButton,
  },
  setup() {
    const store = useStore();
    const itinerary = computed(() => store.state.itinerary);
    const defaultPhoto = require('@/assets/popup/popupHero/hero_default.jpeg');

    const handleImageError = (event) => {
      event.target.src = defaultPhoto; // Path to your default image
    };

    onMounted(() => {
      if (!itinerary.value) {
        //console.log('itineraryHeader mounted. No itinerary selected', itinerary.value);
        store.dispatch('updateView', 'home');
      }
    });

    return {
      itinerary,
      handleImageError,
      defaultPhoto,
    };
  }

}


</script>


<style scoped>


.header-container {
  position: relative;
  width: 100%;
}

.header-background img {
  width: 100%;
  height: 25vh; /* Adjust the height as needed */
  object-fit: cover;
}

.hero-image {
  width: 100%;
  max-width: 100%;
  height: 190px;
  border-radius: 12px 12px 0 0;
  top:0;
  flex-shrink: 0;
  object-fit: cover;
}


.header-content {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.header-title {
  background-color: white;
  border: none;
  padding: 8px 16px 8px 12px;
  margin: 5px;
  font-weight: bold;
  font-size: 1.3em;
  border-radius: 18px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.title-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  position: absolute;
  right: 0;
}


</style>