<template>

    <div class="page-header">
      <DestinationHeader :photo="selectedPhoto"/>
    </div>
    <div class="page-body">
      <div class="photo-gallery">
        <CarouselSlide>
          <PhotoCard 
            v-for="(url, index) in destination.intro_image_urls || [defaultImage]" 
            :key="index" 
            :photo="url"
            @photo-selected="updateSelectedPhoto" 
          />
        </CarouselSlide>
      </div>  

      <div class="body-container">
        <HeaderLevel1
          :title="destination.city + ', ' + destination.country" 
          :hasBorderTop="false"
        />

        <div class="introduction">
          <p>{{ destination.introduction }}</p>
        </div>

        <div class="categories">
          <PrimarySelectButton
            v-for="category in availableCategories"
            :key="category.name"
            :text="category.name"
            :isActive="selectedCategory === category.name"
            @clicked="selectCategory(category.name)"
          />
        </div>

        <div v-for="category in availableCategories" :key="category.name">
          <div v-if="selectedCategory === category.name" class="category-container">
            <!-- <HeaderLevel2 :title="category.name" :hasBorderTop="false" /> -->

            <!-- Render a list or a paragraph depending on the category type -->
            <template v-if="category.type === 'list'">
              <ul>
                <li v-for="(item, index) in destination[category.key]" :key="index">
                  <h3>{{ item.title }}</h3>
                  <h4 v-if="item.sub_title">{{ item.sub_title }}</h4>
                  <p>{{ item.detail }}</p>
                  <img 
                    v-if="item.image_url && !item.imageError"
                    :src="item.image_url"
                    :alt="item.title"
                    @error="handleImageError(item)"
                  />
                </li>
              </ul>
            </template>
            <template v-else>
              <p>{{ destination[category.key] }}</p>
            </template>
          </div>
        </div>

        <div v-if="itineraries && itineraries.length>0" class="suggested-itineraries">
          <HeaderLevel2 title="Suggested Itineraries" />
          <div class="suggested-itineraries-cards">
            <ItineraryCard 
              v-for="itinerary in itineraries" 
              :key="itinerary.id" 
              :itinerary="itinerary" 
            />
          </div>  
        </div>

        <div v-if="destination.nearby_destinations" class="adajcent-cities">
          <HeaderLevel2 title="Adajcent Cities" />
          <div class="adjacent-cities-cards">
            <DestinationCard 
              v-for="destination in destination.nearby_destinations" 
              :key="destination.city" 
              :destination="destination" 
            />
          </div>  
        </div>

      </div> <!-- body-container -->
    </div> <!-- page-body -->


</template>
  
  
  
<script>
import { defineComponent, onMounted, watch, ref, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import DestinationHeader from './DestinationHeader.vue';
import PhotoCard from '@/components/ui/DestinationPage/PhotoCard.vue';
import CarouselSlide from '@/components/ui/util/CarouselSlide.vue';
import HeaderLevel1 from '@/components/ui/util/HeaderLevel1.vue';
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import DestinationCard from '@/components/ui/homePage/DestinationCard.vue';
import ItineraryCard from '@/components/ui/homePage/ItineraryCard.vue';
import PrimarySelectButton from '@/components/ui/util/PrimarySelectButton.vue';
import { getItineraries } from '@/components/services/itineraryService.js';

  
export default defineComponent({
    name: 'DestinationPage',
    components: {
      DestinationHeader,
      PhotoCard,
      CarouselSlide,
      HeaderLevel1,
      HeaderLevel2,
      PrimarySelectButton,
      DestinationCard,
      ItineraryCard,
    },
    setup() {
      const store = useStore();
      const defaultImage = require('@/assets/popup/popupHero/hero_default.jpeg'); // Path to your default image

      const selectedPhoto = ref(defaultImage); // Set default image initially
      const selectedCategory = ref(''); // Default to no category selected');
      const componentKey = ref(0); // Key to force re-rendering of the component

      const itineraries = ref([]); // Array of itineraries

      const destination = computed(() => store.state.destination || {});

      const categories = [
        { name: 'Culture', key: 'culture', type: 'list' },
        { name: 'Attractions', key: 'attractions', type: 'list' },
        { name: 'Key Festivals', key: 'key_festivals', type: 'list' },
        { name: 'Food', key: 'food', type: 'list' },
        { name: 'Shopping', key: 'shopping', type: 'list' },
        { name: 'Transportation', key: 'transportation', type: 'list' }
      ];

      const availableCategories = computed(() =>
        categories.filter(category => store.state.destination[category.key])
      );

      const updateSelectedPhoto = (newPhotoUrl) => {
        selectedPhoto.value = newPhotoUrl;
      };

      const selectCategory = (category) => {
        selectedCategory.value = category;
      };

      const handleImageError = (item) => {
        item.imageError = true;
      };

      // Function to find the first available category
      const findFirstAvailableCategory = () => {
        return availableCategories.value.length > 0 ? availableCategories.value[0].name : '';
      };

      const loadDestination = async (newDestination) => {
        selectedPhoto.value = newDestination.intro_image_urls?.[0] || defaultImage;
        selectedCategory.value = findFirstAvailableCategory() || '';

        // Load itineraries
        itineraries.value = await getItineraries(destination.value.city);
        window.scrollTo(0, 0);  // Scroll to top
      };

      // Watch for changes in the destination state
      watch(
        () => store.state.destination,
          async (newDestination) => {
            //console.log('DestinationPage: Destination changed:', newDestination);
            //console.log('DestinationPage: Nearby Destinations:', newDestination?.nearby_destinations);
            //console.log('DestinationPage: destination:', destination.value);
            if (newDestination) {
              // Update the destinationData with the newly loaded data
              await nextTick();
              loadDestination(newDestination);
              componentKey.value += 1; // Force re-render
            }
          },
          { immediate: true, deep: true }
      );

      onMounted(() => {
        // Load the destination data
        loadDestination(store.state.destination);  
      });
  
      return {
        destination, 
        selectedPhoto,
        updateSelectedPhoto,
        selectedCategory,
        selectCategory,
        availableCategories,
        handleImageError,
        componentKey,
        defaultImage,
        itineraries,
      };
    }
});
</script>
  
<style>


.page-header {
    display: flex;
    min-height: 190px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.page-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
    margin-bottom: 38px;
}

.photo-gallery {
  display: flex;
  width: 100%;
  /* flex-wrap: wrap;
  gap: 16px; */ 
}

.body-container {
  margin: 0px 20px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  margin-bottom: 30px;
}

.category-container {
  margin: 30px 0;
  padding: 10px 28px;
  border-radius: 12px;
  border: 1px solid var(--border-color); 
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}


.category-container ul {
  list-style-type: none;
  padding: 0;
}

.category-container h3 {
  margin-top: 16px;
  margin-bottom: 5px;
  font-size: 1.15em;
}

.category-container h4 {
  margin: 3px 0;
  font-size: 1.15em;
  font-style: italic;
}

.category-container p {
  margin: 0;
  font-size: 1.15em;
}


.category-container img {
  margin: 18px 0px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
}

.adajcent-cities,
.suggested-itineraries {
  /* font-size: 0.9rem; */
  padding: 20px 0;
  margin: 0 auto;
}

.adjacent-cities-cards,
.suggested-itineraries-cards {
  display: flex; /* Ensures child elements are laid out horizontally */
  flex-wrap: wrap; /* Allows cards to wrap onto the next line */
  gap: 8px; /* Adds spacing between cards */
  margin: 5px auto; /* Centers the container if needed */
}



</style>
  