<template>
  <div class="page-header">
    <ItineraryHeader />
  </div>
  <div v-if="itinerary" class="page-body">
    <div class="itinerary-container">
      <div class="itinerary-header">
        <HeaderLevel1 :title="decodeHTMLEntities(itinerary?.name)" :hasBorderTop="false" />
        <div class="itinerary-highlight">
          <HeaderLevel2 :title="'Highlight'" :hasBorderTop="false" />
          <ul>
            <li v-if="itinerary?.route"><span><img src="@/assets/cityPage/pin.png" alt="Route" class="title-icon">{{ itinerary.route }}</span></li>
            <li v-if="itinerary?.trip_duration"><span><img src="@/assets/cityPage/calendar.png" alt="Date" class="title-icon">{{ itinerary.trip_duration }}</span></li>
            <li v-if="itinerary?.available_months"><span><img src="@/assets/cityPage/season.png" alt="Season" class="title-icon">{{ itinerary.available_months }}</span></li>
            <li v-if="itinerary?.group_size"><span><img src="@/assets/cityPage/people.png" alt="group size" class="title-icon">{{ itinerary.group_size }}</span></li>
          </ul>
        </div> <!-- itinerary-highlight -->

        <div v-html="decodeHTMLEntities(itinerary?.description)"></div>
      </div> <!-- itinerary-header -->

      <div class="trip-itinerary">
        <HeaderLevel2 :title="'Trip Itinerary'" :hasBorderTop="false" />
        <div class="carousel-container">
          <button class="carousel-button left" @click="scrollLeft" v-show="!isAtStart">&#10094;</button>

          <div class="day-tabs" @scroll="checkScroll">
            <div 
                v-for="(day, index) in itinerary?.alldays" 
                :key="index" 
                class="day-tab"
                :class="{ active: selectedDayIndex === index }"
                @click="selectDay(index)" 
            >
              <span class="day-tab-day-id">{{ 'Day ' + day.day_id }}</span>
              <span class="day-tab-title">{{ day.title }}</span>
              <img 
                :src="getIconSrc(index)" 
                alt="" 
                class="day-tab-icon" 
              />
            </div> <!-- day-tab -->
          </div> <!-- day-tabs --> 

          <button class="carousel-button right" @click="scrollRight" v-show="!isAtEnd">&#10095;</button>
        </div> <!-- carousel-container -->

        <GoogleMapView 
          v-if="itinerary?.destinations && itinerary?.destinations?.length > 0"
          :cities="itinerary.destinations" 
        />

        <div class="day-details" v-if="selectedDay">
          <h3>
            <span>
              <img src="@/assets/cityPage/details.png" alt="day details" class="header-icon">{{ 'Day ' + selectedDay.day_id }}: {{ selectedDay.title }}
            </span>
          </h3>
          <div v-html="decodeHTMLEntities(selectedDay.description)"></div>
        </div> <!-- day-details -->

        <div v-if="itinerary?.travel_tips" class="trip-tips">
          <HeaderLevel2 :title="'Trip Tips'" />
          <div v-html="decodeHTMLEntities(itinerary?.travel_tips)"></div>
        </div>

        <orderButton
          :buttonText="'Package Options'"
          @click="goToOrderPage"
        />

        <div v-if="itinerary?.destinations && itinerary?.destinations.length>0" class="cities">
          <HeaderLevel2 :title="'Cities of the Itinerary'" />
          <div class="cities-cards">
            <DestinationCard 
              v-for="location in cities" 
              :key="location.city" 
              :destination="location" 
            />
          </div> <!-- cities cards-->
        </div> <!-- cities -->


      </div> <!-- trip-itinerary -->

    </div> <!-- itinerary-container -->
  </div> <!-- page-body -->
  <div class="loading-indicator" v-else>
      <LoadingSpinner />
  </div>
</template>

    

<script>
import { ref, computed, onMounted, onUpdated, nextTick } from 'vue';
import GoogleMapView from '@/components/ui/cityPage/GoogleMapView.vue';
import { useStore } from 'vuex';
import LoadingSpinner from '../chatPage/LoadingSpinner.vue';
import DestinationCard from '@/components/ui/homePage/DestinationCard.vue';
import ItineraryHeader from './ItineraryHeader.vue';
import { getItinerary } from '@/components/services/itineraryService';
import { decodeHTMLEntities, stripHtmlTags } from '@/components/services/util';
import HeaderLevel1 from '@/components/ui/util/HeaderLevel1.vue';
import HeaderLevel2 from '@/components/ui/util/HeaderLevel2.vue';
import orderButton from '@/components/ui/util/orderButton.vue';
// import { searchHotels } from '@/components/services/hotelService';
// import HotelCard from '@/components/ui/homePage/HotelCard.vue';

export default {
  name: 'ItineraryPage',
  components: {
    GoogleMapView,
    ItineraryHeader,
    DestinationCard,
    HeaderLevel1,
    HeaderLevel2,
    orderButton,
    LoadingSpinner,
    // HotelCard,
  },
  setup() {
    const itinerary = ref({
      travel_tips: '',
      alldays: [],
      destinations: []
    });
    const selectedDayIndex = ref(0);
    const isAtStart = ref(true);
    const isAtEnd = ref(false);
    let cities = ref([]);

    const store = useStore();
    const selectedItinerary = computed(() => store.state.itinerary);

    let selectedHotelId = null;
    // let hotels = ref([]);

    const loadItinerary = async () => {
      //console.log('Loading Itinerary:', store.state.itinerary.id);
      itinerary.value = await getItinerary(selectedItinerary.value.id);
      cities.value = itinerary.value?.destinations?.map(destination => destination.location).filter((location, index, self) => 
        index === self.findIndex(loc => loc.city.toLowerCase() === location.city.toLowerCase())
      );
      //console.log('Itinerary:', itinerary.value);
      //console.log('Travel Tips:', travelTips);
      //console.log('Cities:', cities);
    };
        
    const selectedDay = computed(() => {
      return itinerary.value.alldays ? itinerary.value.alldays[selectedDayIndex.value] : null;
    });

    const scrollLeft = () => {
      const container = document.querySelector('.day-tabs');
      container.scrollBy({ left: -200, behavior: 'smooth' });
      checkScroll();
    };

    const scrollRight = () => {
      const container = document.querySelector('.day-tabs');
      container.scrollBy({ left: 200, behavior: 'smooth' });
      checkScroll();
    };

    const checkScroll = () => {
      const container = document.querySelector('.day-tabs');
      if (container) {  // Only proceed if container is found
        isAtStart.value = container.scrollLeft === 0;
        isAtEnd.value = container.scrollWidth <= container.scrollLeft + container.clientWidth;
      }
    };


    const getIconSrc = (index) => {
      if (selectedDayIndex.value === index) {
        return index === 0 ? require('@/assets/cityPage/flight-takeoff-white.png') : require('@/assets/cityPage/camera-white.png');
      }
      return index === 0 ? require('@/assets/cityPage/flight-takeoff.png') : require('@/assets/cityPage/camera.png');
    };

    const selectDay = (index) => {
      selectedDayIndex.value = index;
    };

    const selectHotel = (hotelId) => {
      selectedHotelId.value = hotelId;
    }

    const goToOrderPage = () => {
      window.open(`https://www.triptogo.com/details?id=${selectedItinerary.value.id}`, '_blank', 'noopener,noreferrer');
    };

    onMounted(async () => {
      await loadItinerary();
      await nextTick();  // Wait for the DOM to update
      checkScroll();
    });

    onUpdated(async () => {
      await nextTick();  // Wait for DOM changes after updates
      checkScroll();
    });

    return {
      decodeHTMLEntities,
      stripHtmlTags,
      selectedItinerary,
      itinerary,
      cities,
      selectedDayIndex,
      isAtStart,
      isAtEnd,
      selectedDay,
      scrollLeft,
      scrollRight,
      checkScroll,
      getIconSrc,
      selectDay,      
      selectHotel,
      goToOrderPage,
    };
  },
};
</script>


    
  
<style scoped>
.page-header {
    display: flex;
    min-height: 190px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.page-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
}

.hotel-container {
  /* font-size: 0.9rem; */
  padding: 20px;
  margin: 0 auto;
}

.hotel-rows {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.hotel-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


.itinerary-container {
  font-size: 1rem;
  padding: 20px;
  margin: 0 auto;
}

.itinerary-header {
  margin-bottom: 20px;
}

.itinerary-highlight ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.itinerary-highlight li {
  font-weight: 600;
  display: inline;
  margin-right: 16px;
  margin-bottom: 5px;
}

.itinerary-highlight span {
  white-space: nowrap;
}

.title-icon {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.trip-itinerary {
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
}

.trip-itinerary .day-plan {
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
  margin-top: 10px;
}

.day-tabs {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  --webkit-overflow-scrolling: touch;
  padding: 10px 0;
  margin: 0 12px;
}

.day-tabs > * {
  flex: 0 0 13%;
  scroll-snap-align: start;
}

.day-tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better UX */
}

.day-tabs :hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab {
  position: relative;
  width: 90px;
  flex: 0 0 auto;
  padding: 10px;
  margin: 5px;
  text-align: left;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.day-tabs :hover {
  background-color: var(--secondary-color);
  color: white;
}

.day-tab.active {
  background-color: var(--primary-color);
  color: white;
}

.day-tab.active:hover {
  background-color: var(--primary-color);
  color: white;
}

.day-tab-day-id {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
  pointer-events: none;  /* Prevents hover effects */
}

.day-tab-day-id:hover {
  background-color: transparent;
  color: inherit;
}

.day-tab-title {
  font-size: 0.85rem;
}

.day-tab-title:hover {
  background-color: transparent;
  color: inherit;
}


.day-tab-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
}

.carousel-button {
  background-color: white;
  color: var(--primary-color);
  outline: none;
  border-radius: 50%;
  /* box-sizing: border-box; */
  width: 25px;
  height: 25px;
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

.carousel-button:disabled {
  /* cursor: not-allowed; */
  opacity: 0.5;
  color: #ccc; /* Disabled button color */
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.day-details {
  background-color: white;
  margin-top: 10px;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  padding: 0 10px 10px 10px;
}

.day-details h3 span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.day-details li {
    margin-bottom: 10px;
  }

.trip-tips {
  margin-top: 30px;
  padding: 0 10px 10px 10px;
}

.cities {
  /*margin-top: 30px;*/
  padding: 0 10px 10px 10px;
}

.cities-cards {
  display: flex; /* Ensures child elements are laid out horizontally */
  flex-wrap: wrap; /* Allows cards to wrap onto the next line */
  gap: 8px; /* Adds spacing between cards */
  margin: 5px auto; /* Centers the container if needed */
}

.header-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1em;
}
</style>
  