<template>
  <div class="google-map">
    <GoogleMap
      v-if="cities.length > 0"
      :api-key="apiKey"
      style="width: 100%; height: 100%;"
      :center="cities[Math.floor(cities.length / 2)]"
      :zoom="6"
      :mapTypeControl="false"
      :streetViewControl="false"
      language="en"
    >
      <MarkerCluster>
        <Marker
          v-for="(location, i) in cities"
          :key="i"
          :options="{
            position: location,
            icon: bluePinIcon,
            label: {
              text: location.name, // Label is the name of the city
              color: '#000000', // Black text color
              fontSize: '12px', // Custom font size for the label
              fontWeight: 'bold' // Bold font weight
            }
          }"
        />
      </MarkerCluster>
      <Polyline v-if="flightPath.path.length > 1" :options="flightPath" />
    </GoogleMap>
  </div>
</template>

<script>
import { GoogleMap, Marker, MarkerCluster, Polyline } from 'vue3-google-map';
import { GOOGLE_MAPS_API_KEY } from '@/config';

export default {
  name: 'GoogleMapView',
  components: {
    GoogleMap,
    Marker,
    MarkerCluster,
    Polyline,
  },
  props: {
    cities: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiKey: GOOGLE_MAPS_API_KEY,
      bluePinIcon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Use the blue marker icon
      flightPath: {
        path: [],
        geodesic: true,
        strokeColor: '#0000FF',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [
          {
            icon: {
              path: 1,
              scale: 3,
              strokeColor: '#0000FF',
            },
            offset: '100%',
          },
        ],
      },
      // googleMarkerIcon: {
      //   path: 1,
      //   scale: 8,
      //   fillColor: '#0000FF',
      //   fillOpacity: 1.0,
      //   strokeWeight: 0,
      // },
      duration: 5000,
      startTime: null,
      marker: null, // Initialize marker variable
    };
  },
  mounted() {
    //console.log('cities:', this.cities);
    if (this.cities.length > 1) {
      this.setFlightPath(this.cities);
      //this.createMarker(); // Initialize the marker
      requestAnimationFrame(this.animate); // Start animation
    }
  },
  methods: {
    setFlightPath(cities) {
      this.flightPath.path = cities.map((city) => ({
        lat: city.lat,
        lng: city.lng,
      }));
    },
    interpolateCoordinates(start, end, percentage) {
      const lat = start.lat + (end.lat - start.lat) * percentage;
      const lng = start.lng + (end.lng - start.lng) * percentage;
      return { lat, lng };
    },
    createMarker() {
      // Create the marker for animation
      this.marker = new window.google.maps.Marker({
        position: this.cities[0],
        map: this.$refs.mapInstance, // Reference to the Google map instance
        icon: this.bluePinIcon,
      });
    },
    animate(timestamp) {
      if (!this.startTime) this.startTime = timestamp;
      const progress = timestamp - this.startTime;
      const percentage = Math.min(progress / this.duration, 1);

      const newPosition = this.interpolateCoordinates(
        this.cities[0],   // Start location
        this.cities[this.cities.length - 1], // End location
        percentage
      );

      // Update the marker's position
      if (this.marker) {
        this.marker.setPosition(newPosition);
      }

      if (percentage < 1) {
        requestAnimationFrame(this.animate);
      }
    },
  },
};
</script>

<style scoped>
.google-map {
  width: 96%;
  height: 25vh; /* 1/4 of the viewport height */
  margin: 15px;
  border-radius: 16px;
  border: 2px solid #e0e0e0;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>